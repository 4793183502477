@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  .scene-10 {
    &__grid-bg,
    &__gradient-bg {
      position: absolute;
      top: 0;
      left: 0;
    }
    &__gradient-bg {
      width: 100%;
      height: 100%;
      background: radial-gradient(41.27% 52.32% at 65.78% 57.36%, #1F84E2 0%, #011F2E 100%);
    }
    &__grid-bg {
      width: 100%;
    }
    &__phone-blueprint {
      bottom: 19.8%;
      right: 19.2%;
      width: 28%;
    }
    &__phone-wireframe {
      bottom: 37.3%;
      right: 6.7%;
      width: 27%;
    }
    &__cube,
    &__cube-sm,
    &__cube-lg {
      width: 10%;
    }

    &__cubes {
      bottom: 2.6%;
      right: 1.8%;
      width: 52.6%;
    }

    &__logos {
      position: absolute;
      top: 58%;
      left: 15%;
      width: 12%;
      img {
        position: absolute;
        left: 50%;
      }

      .android-logo {
        transform: translate(-50%, -50%);
        width: 100%;
      }

      .apple-logo {
        transform: translate(-50%, -50%);
        width: 120%;
      }

    }
  }
}