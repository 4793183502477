@import '../../../stylesheets/variables';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.ais-SearchBox {
  position: relative;
  margin-bottom: 10px;

  &::before {
    position: absolute;
    content: "";
    top: 9px;
    left: 8px;
    width: 20px;
    height: 20px;
    background-image: url("https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/magnifying-glass.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .ais-SearchBox-input {
    @extend %default_font-family;
    width: 100%;
    border: 1px solid #eeeeee;
    background-color: #f2f2f2;
    border-radius: .25rem;
    padding: 6px 180px 4px 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    color: #2a2a2a;
    &:focus {
      background-color: #ffffff;
      border-color: #f0f0f0;
      box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.2);
    }
  }

  .ais-SearchBox-input,
  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    &:focus {
      outline: 0;
    }
  }

  .ais-SearchBox-submitIcon {
    display: none;
  }

  .ais-SearchBox-reset {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 25px;
    height: 25px;
    background-image: url("https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/close-x-green.svg");
    background-color: transparent;
    background-repeat: no-repeat;
    border: 0;
  }
}

.ais-PoweredBy {
  position: absolute;
  top: 45px;
  right: 1rem;

  .ais-PoweredBy-logo {
    width: 110px !important;
  }
}

.ais-Stats-text {
  font-size: .9rem;
  color: #9a9a9a;
}

// dark mode

[data-bs-theme="dark"] {

}