@import "animations.scss";
@import "mixins.scss";

.btn {
	--bs-btn-font-size: .85rem;
	letter-spacing: .4px;
	&:active {
		transform: scale(0.98);
		opacity: .7;

		img {
			margin-left: .5rem;
		}
	}
	// Elements
	// Buttons
	&-primary {
		color: var(--bs-white);
		&:hover,
		&:focus {
			background-color: darken($primary, 2%);
			color: $off-white;
		}
		&-outline {
			color: initial;
			border-color: $primary;
			background-color: transparent;
			&:hover,
			&:focus {
				background-color: transparent;
				border-color: darken($primary, 5%);
			}
		}
	}
	&-tertiary {
		color: var(--bs-white);
		background-color: $tertiary;
		&:hover,
		&:focus {
			background-color: darken($tertiary, 5%);
			color: $off-white;
		}
		&-outline {
			color: initial;
			border-color: $tertiary;
			background-color: transparent;
			&:hover,
			&:focus {
				background-color: transparent;
				border-color: darken($tertiary, 5%);
			}
		}
	}
	&-light {
		color: var(--bs-body-color);
		--bs-btn-bg: #ececec;
	}
	&-dark {
		color: $gray;
		background-color: $secondary;
		&:hover,
		&:focus {
			background-color: $gray;
			color: $secondary;
			.btn-icon {
				filter: invert(100%);
			}
		}
	}
	&-pre-order {
		color: var(--bs-white);
		background-color: $secondary;
		&:hover,
		&:focus {
			color: darken($white, 20%);
			background-color: lighten($secondary, 5%);
		}
	}
	&-danger {
		color: var(--bs-white);
		background-color: $retail;
		&:hover,
		&:focus {
			background-color: darken($retail, 2%);
		}
	}
	&-flow {
		display: inline-flex;
		position: relative;
		background: linear-gradient(
			to right,
			rgba(3, 213, 255, 0.9),
			rgba(131, 71, 252, 0.9)
		);
		background-size: 100%;
		color: #ffffff;
		transition-property: background-color, background-size;
		transition-duration: 300ms;
		transition-timing-function: ease;
		align-items: center;
		z-index: 0;
		&::before {
			position: absolute;
			content: '';
			top: -2px;
			right: -2px;
			bottom: -2px;
			left: -2px;
			background: linear-gradient(
				to right,
				rgba(3, 213, 255, 0.9),
				rgba(116, 87, 250, 0.9)
			);
			box-shadow: 0 2px 6px 0 rgba(71, 114, 252, 0.4);
			transition-property: background-size, box-shadow;
			transition-duration: 300ms;
			transition-timing-function: ease;
			z-index: -1;
		}
		&:after {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.09);
			transition: background-color 300ms ease;
			z-index: -1;
		}
		&:hover,
		&:active {
			background-size: 300%;
			color: var(--bs-white);
			&::before {
				box-shadow: 0 2px 6px 3px rgba(71, 114, 252, 0.6);
			}

			&::after {
				background-color: rgba(0, 0, 0, 0);
			}
		}
	}
	&-unfocus {
		&-primary {
			@include btn-unfocus($primary);
		}
		&-secondary {
			@include btn-unfocus($secondary);
		}
		&-danger {
			@include btn-unfocus($danger);
		}
	}
	// Text-Only Buttons
	&-link {
		&-primary {
			@include btn-link($primary);
		}
		&-secondary {
			@include btn-link($secondary);
		}
		&-danger {
			@include btn-link($danger);
		}
		&-light {
			@include btn-link($light);
		}
		&-white {
			@include btn-link($white);
		}
	}
}
.btn-2020 {
	height: 44px;
	padding: 0 22px;
	font-size: .85rem;
	&,
	&::before,
	&::after {
		border-radius: 24px;
	}
	// Child elements
	.btn-icon {
		transition: .6s;
		&.right-arrow {
			width: 28px;
			height: 28px;
			margin-top: -3px;
			margin-right: -9px;
			margin-left: auto;
		}
	}
}
.btn-2021 {
	padding: .5rem 0.9rem;
	border-width: 2px;
	border-radius: 24px;
	border-style: solid;
	background-origin: border-box;
	font-size: 1.125rem;
	line-height: 1.44;
	letter-spacing: .4px;
	&::before,
	&::after {
		border-radius: 24px;
	}
}
.btn,
.btn-2020 {
	box-sizing: border-box;
	text-align: center;
	letter-spacing: .4px;
}
.btn,
.btn-2020,
.btn-2021 {
	position: relative;
	font-weight: 700;
	.btn-icon {
		transition: .6s;
		&.right-arrow,
		&.download-arrow {
			width: 20px;
			height: 20px;
			margin-top: -0.5rem;
			margin-bottom: -0.3rem;
			margin-left: .3rem;
			margin-right: -0.1rem;
			text-align: center;
			transition: .3s;
		}
		&.download-arrow {
			width: 24px;
			height: 24px;
		}
	}
	&.btn-w100 {
		width: 100%;
		text-align: left;
		.btn-icon {
			float: right;
			margin: 0;
		}
		&:hover {
			& .btn-icon {
				animation: bounceRight 1s ease;
			}
		}
	}
}


