.ais-InstantSearch {
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    margin-top: 2rem;
  }

  #ais-InstantSearch-col {
    position: static;
  }

  ol,
  ul {
    list-style: none;
    padding-inline-start: 0;

    li {
      padding: 0;

      &:before {
        display: none;
      }
    }
  }

  .search {
    position: relative;
    margin-bottom: 15px;
  }

  #params {
    margin-top: 1rem;
    margin-bottom: 2rem;

    /**
      On mobile filter shown as drawer
      on desktop shown static
      no need for toggle on desktop
    */
    @include media-breakpoint-up(lg) {
      display: none;
    }

  }

  .results {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: initial;
      margin-top: 10px;
    }

    .left-panel {
      position: fixed;
      width: 90%;
      height: 100vh;
      top: 0;
      right: -90%;
      border-left: 1px solid var(--bs-border-color);
      background-color: var(--bs-body-bg);
      @extend %default_font-family;
      transition: right 1s;
      z-index: 1031;

      @include media-breakpoint-up(md) {
        width: 35%;
        right: -35%;
      }
      @include media-breakpoint-up(lg) {
        position: relative;
        width: auto;
        height: 100%;
        top: auto;
        right: auto;
        margin-right: 20px;
        border: 0;
        z-index: 0;
      }

      &.active {
        right: 0;
      }

      #refinement-functions {
        position: relative;
        height: 50px;
      }

      #refinement-list {
        height: 100%;
        border-top: 1px solid #eeeeee;
        overflow-y: auto;

        @include media-breakpoint-up(lg) {
          width: 200px;
          height: auto;
          margin-right: 20px;
          border-radius: .25rem;
          border-color: #f0f0f0;
          box-shadow: 0 4px 12px -1px rgba(0, 0, 0, 0.2);
        }

        #refine-title {
          font-size: 1rem;
          margin: 0;
          padding: 1rem 0;
        }
      }

      #clear-refinements {
        float: left;
        margin: 12px 0 0 20px;
      }

      #clear-refinements button {
        color: $primary;
        border: 0;
        padding: 0;
        margin: 0;
        background-color: transparent;
        cursor: pointer;
      }

      #apply-refinements {
        float: right;
        margin: 12px 20px 0 0;
        color: $primary;
        border: 0;
        background-color: transparent;
        cursor: pointer;
      }

      .ais-Panel {
        &--noRefinement {
          display: none;
        }

        &-header {
          background-color: #e5e5e5;
          padding: 5px 20px;
          margin-bottom: 10px;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 11px;
        }

        &-body {
          padding: 0 20px;

          .ais-RefinementList {
            &-list {
              margin-bottom: 5px;
              padding-left: 0;
            }

            &-label {
              display: block;
            }

            &-checkbox {
              vertical-align: top;
              margin-top: 9px;
            }

            &-labelText {
              display: inline-block;
              font-size: .8125rem;
              padding-left: 5px;
              max-width: 140px;
            }

            &-count {
              display: none;
            }

            &-showMore {
              margin-bottom: 20px;
              padding: 0;
              background-color: transparent;
              border: 0;
              font-size: .8215rem;
              font-weight: 700;

              img {
                width: 1rem;
                display: inline-block;
                vertical-align: top;
                margin-top: 1px;
                margin-right: 3px;
              }
            }
          }

          .ais-HierarchicalMenu {
            &-item--selected {
              &>a {
                font-weight: 700;
              }

              ul {
                padding-left: .5rem;
              }
            }

            &-label {
              display: inline-block;
              font-size: .8125rem;
              padding-left: 5px;
              width: 100%;
              max-width: 140px;
              color: $midnight;
            }

            &-count {
              display: none;
            }
          }
        }
      }

    }

    .right-panel {
      width: 100%;
      height: auto;

      #banner {
        img {
          border-radius: .5rem;

          &:hover {
            opacity: .8;
          }
        }
      }

      .display-card {
        position: relative;
        width: 100%;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2.5rem;
        border-radius: .5rem;
        border: solid 1px $light-gray;
        overflow: hidden;

        @include media-breakpoint-up(md) {
          max-width: 30%;
          margin-left: initial;
          margin-right: 5%;
          margin-bottom: 4rem;

          @include media-breakpoint-up(md) {
            &:nth-child(3n) {
              margin-right: 0;
            }
          }
        }

        &-content {
          padding: 1rem;
          margin-bottom: 3rem;

          h4 {
            font-weight: 700;
          }

          p {
            font-size: .925rem;
            line-height: 1.5;
            letter-spacing: .3px;
          }

          .btn {
            position: absolute;
            width: calc(100% - 2rem);
            bottom: 1rem;
          }
        }
      }

      .no-device-result {
        padding: 50px 0;
        text-align: center;

        span {
          color: $primary;
          cursor: pointer;
        }
      }

      #search-footer {
        display: block;
        margin-bottom: 3rem;

        @include media-breakpoint-up(md) {
          display: flex;
          justify-content: space-between;
        }
      }

      #pagination {
        text-align: center;
        margin-bottom: 2rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }

        ul {
          li {
            display: inline-block;
            padding: 1px 10px;
            border: 1px solid #f0f0f0;
            border-radius: .25rem;
            margin-right: 5px;
            background-color: #eeeeee;

            a {
              color: #242424;
            }

            &.ais-Pagination-item--selected {
              background-color: $primary;
              border-color: $primary;

              a {
                color: var(--bs-body-color);
              }
            }

            &.ais-Pagination-item--disabled {
              span {
                color: #9a9a9a;
              }
            }
          }
        }
      }

      #hits-per-page {
        position: relative;

        @include media-breakpoint-up(lg) {
          &::before {
            position: absolute;
            content: "Results Per Page";
            width: 115px;
            top: 8px;
            right: 145px;
            text-align: right;
            font-size: .9rem;
          }
        }

        .ais-HitsPerPage-select {
          width: 100%;
          height: calc(1.5em + 0.75rem + 2px);
          padding: .375rem 1.75rem 0.375rem 0.75rem;
          color: #495057;
          border: 0;
          border-radius: .25rem;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          position: relative;
          background: url(https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/down-chevron-black.svg) 97%/6% no-repeat #f2f2f2;

          @include media-breakpoint-up(lg) {
            background: url(https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/down-chevron-black.svg) 97% / 13% no-repeat #f2f2f2;
          }
        }
      }
    }
  }

}

[data-bs-theme="dark"] {
  .ais-InstantSearch {
    .results {
      .left-panel {
        #refinement-list {
          border: 1px solid #242424;
        }
        .ais-Panel {
          &-header {
            background: $secondary
          }
          &-body {
            .ais-HierarchicalMenu {
              &-label {
                color: var(--bs-body-color);
              }
            }
          }
        }
      }
      .right-panel {
        .display-card {
          border-color: #242424;
        }
        #pagination {
          ul {
            li {
              border-color: $secondary;
              background-color: $secondary;

              a {
                color: $off-white;
              }

              &.ais-Pagination-item--selected {
                background-color: $primary;
                border-color: $primary;

                a {
                  color: var(--bs-white);
                }
              }

              &.ais-Pagination-item--disabled {
                span {
                  color: #9a9a9a;
                }
              }
            }
          }
        }
        #hits-per-page {
          .ais-HitsPerPage-select {
            background-color: $secondary;
            color: $off-white;
            border: 0;
            background: url(https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/down-chevron-white.svg) 97%/6% no-repeat $secondary;

            @include media-breakpoint-up(lg) {
              background: url(https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/down-chevron-white.svg) 97% / 13% no-repeat $secondary;
            }
          }
        }
      }
    }
  }
}