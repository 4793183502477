@import '../../stylesheets/variables';

.theme-toggle-switch-2023 {
	display: flex;
	align-items: center;
	.form-switch {
		padding-left: 2.8rem;
	}
	.icon {
		transition: fill .2s ease;
		&__sun {
			width: 18px;
			height: 18px;
			fill: $hospitality;
		}
		&__moon {
			width: 12px;
			height: 12px;
			fill: #777777;
		}
	}
} 

[data-bs-theme="dark"] {
	.theme-toggle-switch-2023 {
		.icon {
			&__sun {
				fill: #777777;
			}
			&__moon {
        fill: $healthcare;
      }
		}
	}
}