@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  .scene-14 {
    &__handshake {
      bottom: 0;
      right: 0;
      width: 80%;
      mix-blend-mode: color-dodge;
    }
  }
}