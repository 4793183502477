@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  .scene-7 {
    &__mp43 {
      top: 5%;
      left: 0;
      width: 45%;
    }
    &__lkp400 {
      top: 24%;
      left: 34%;
      width: 45%;
    }
    &__lkp300 {
      top: 39%;
      left: 59%;
      width: 40%;
    }
    &__mp22 {
      top: 49%;
      left: 24%;
      width: 30%;
    }
    &__wpp250 {
      top: 70%;
      left: 56%;
      width: 18%;
    }
  }
}