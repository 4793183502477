@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  .scene-9 {
    &__gradient-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(62.36% 62.36% at 50% 37.64%, #026ED1 0%, #094C63 50%, #000 100%);
    }
    &__usa-map {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    &__data-stream {
      &-1, &-2, &-3, &-4 {
        width: 15%;
      }
      &-1 {
        top: 50%;
        left: 9%;
      }
      &-2 {
        top: 61%;
        left: 25%;
      }
      &-3 {
        top: 30%;
        left: 41%;
      }
      &-4 {
        top: 30%;
        left: 77.5%;
      }
    }
    &__server-stack {
      &-1, &-2 {
        width: 7%;
      }
      &-1 {
        top: 18%;
        left: 33%;
      }
      &-2 {
        top: 32%;
        left: 68%;
      }
    }
    &__server-rack {
      &-1, &-2 {
        width: 4.5%;
      }
      &-1 {
        top: 11%;
        left: 78.2%;
      }
      &-2 {
        top: 59%;
        left: 43.4%;
      }
    }
    &__user {
      top: 51%;
      left: 29%;
      width: 10%;
    }
    &__mobile-phone {
      top: 30%;
      left: 23.5%;
      width: 3.3%;
    }
    &__laptop {
      top: 78%;
      left: 73%;
      width: 5.5%;
    }
    &__cloud-data-transfer {
      top: 64%;
      left: 53%;
      width: 6%;
    }
  }
}