i.i {
  position: relative;
  display: inline-block;
  &, &::before {
    width: 40px;
    height: 40px;
  }
  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &.i {
    // Quantum
    &-quantum {
      &-q {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1591136065/icons/quantum-white.svg');
        }

        &--white::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1591136065/icons/quantum-white.svg');
        }

        &--black::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1591136065/icons/quantum-black.svg');
        }

        &--green::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1591136065/icons/quantum-white.svg');
        }
      }
      &-iq {
        &:before {}

        &--white {
          &::before {}
        }

        &--black {
          &::before {}
        }

        &--green {
          &::before {}
        }
      }
      &-browser {
        &:before {}

        &--white {
          &::before {}
        }

        &--black {
          &::before {}
        }

        &--green {
          &::before {}
        }
      }
      &--wedge {
        &:before {}

        &--white {
          &::before {}
        }

        &--black {
          &::before {}
        }

        &--green {
          &::before {}
        }
      }
      &-pay {
        &:before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1616689994/logos/q-pay-icon-white.svg');
        }

        &--white {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1616689994/logos/q-pay-icon-white.svg');
          }
        }

        &--black {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1616689994/logos/q-pay-icon-black.svg');
          }
        }

        &--green-white {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1616689994/logos/q-pay-icon-green-white.svg');
          }
        }
        &--green-black {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1616689994/logos/q-pay-icon-green-black.svg');
          }
        }
      }
      &-native {
        &:before {}

        &--white {
          &::before {}
        }

        &--black {
          &::before {}
        }

        &--green {
          &::before {}
        }
      }
      &-hybrid {
        &:before {}

        &--white {
          &::before {}
        }

        &--black {
          &::before {}
        }

        &--green {
          &::before {}
        }
      }
      &-vision {
        &:before {}

        &--white {
          &::before {}
        }

        &--black {
          &::before {}
        }

        &--green {
          &::before {}
        }
      }
    }
    // arrows
    &-chevron {
      &-up {
        &--black {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544812857/icons/up-chevron-black.svg');
          }
        }

        &--green {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544812857/icons/up-chevron-green.svg');
          }
        }

        &--white {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544812857/icons/up-chevron-white.svg');
          }
        }
      }

      &-right {
        &--black {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544808763/icons/right-chevron-black.svg');
          }
        }

        &--green {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544808763/icons/right-chevron-green.svg');
          }
        }

        &--white {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544808763/icons/right-chevron-white.svg');
          }
        }
      }

      &-down {
        &--black {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544812790/icons/down-chevron-black.svg');
          }
        }

        &--green {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544812790/icons/down-chevron-green.svg');
          }
        }

        &--white {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544812790/icons/down-chevron-white.svg');
          }
        }
      }

      &-left {
        &--black {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544808763/icons/left-chevron-black.svg');
          }
        }

        &--green {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544808763/icons/left-chevron-green.svg');
          }
        }

        &--white {
          &::before {
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544808763/icons/left-chevron-white.svg');
          }
        }
      }
    }
    &-left-arrow {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1605228764/icons/left-arrow-black.svg');
        }
      }
      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1605228764/icons/left-arrow-white.svg');
        }
      }
    }
    &-right-arrow {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544808763/icons/right-arrow-black.svg');
        }
      }
      &--green {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544808762/icons/right-arrow-green.svg');
        }
      }
      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1544808763/icons/right-arrow-white.svg');
        }
      }
    }
    // video/audio
    &-fullscreen {
      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1591136065/icons/fullscreen-white.svg');
        }
      }
    }
    &-mute {
      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1591136065/icons/mute-white.svg');
        }
      }
    }
    &-unmute {
      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1591136065/icons/unmute-white.svg');
        }
      }
    }
    &-play {
      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1591136065/icons/play-white.svg');
        }
      }
    }
    // value props
    &-1d2d {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929914/value-proposition/2020/1d-2d-black.svg');
        }
      }
      &--green {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929916/value-proposition/2020/1d-2d-green.svg');
        }
      }
      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929916/value-proposition/2020/1d-2d-white.svg');
        }
      }
    }
    &-ip65 {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1609863426/value-proposition/2020/ip-rated-black.svg');
        }
      }

      &--green {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1609863426/value-proposition/2020/ip-rated-green.svg');
        }
      }

      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1609863429/value-proposition/2020/ip-rated-white.svg');
        }
      }
    }
    &-keypad {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1619041972/value-proposition/2020/keypad-black.svg');
        }
      }

      &--green {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1619041972/value-proposition/2020/keypad-green.svg');
        }
      }

      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1619041972/value-proposition/2020/keypad-white.svg');
        }
      }
    }
    &-lightning {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929914/value-proposition/2020/lightning-connector-black.svg');
        }
      }
    }
    &-lock {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929888/value-proposition/2020/lock-black.svg');
        }
      }
      &--green {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929888/value-proposition/2020/lock-green.svg');
        }
      }
      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929888/value-proposition/2020/lock-white.svg');
        }
      }
    }
    &-passthrough {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929914/value-proposition/2020/pass-through-charging-black.svg');
        }
      }

      &--green {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929916/value-proposition/2020/pass-through-charging-green.svg');
        }
      }

      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929916/value-proposition/2020/pass-through-charging-white.svg');
        }
      }
    }
    &-payment-methods {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1619025135/value-proposition/2020/payment-methods-black.svg');
        }
      }
      &--green {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1619025135/value-proposition/2020/payment-methods-green.svg');
        }
      }
      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1619025135/value-proposition/2020/payment-methods-white.svg');
        }
      }
    }
    &-programmable-buttons {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929913/value-proposition/2020/programmable-buttons-black.svg');
        }
      }
      &--green {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929913/value-proposition/2020/programmable-buttons-green.svg');
        }
      }
      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929914/value-proposition/2020/programmable-buttons-white.svg');
        }
      }
    }
    &-protected {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929914/value-proposition/2020/protected-black.svg');
        }
      }
      &--green {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929916/value-proposition/2020/protected-green.svg');
        }
      }
      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929916/value-proposition/2020/protected-white.svg');
        }
      }
    }
    &-seamless-integration {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929928/value-proposition/2020/seamless-integration-black.svg');
        }
      }

      &--green {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929928/value-proposition/2020/seamless-integration-green.svg');
        }
      }

      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929929/value-proposition/2020/seamless-integration-white.svg');
        }
      }
    }
    &-slim {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929914/value-proposition/2020/slim-black.svg');
        }
      }

      &--green {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929916/value-proposition/2020/slim-green.svg');
        }
      }

      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929916/value-proposition/2020/slim-white.svg');
        }
      }
    }
    &-smart-battery {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929935/value-proposition/2020/smart-battery-black.svg');
        }
      }
      &--green {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929935/value-proposition/2020/smart-battery-green.svg');
        }
      }
      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929936/value-proposition/2020/smart-battery-white.svg');
        }
      }
    }
    &-swappable-battery {
      &--black {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929941/value-proposition/2020/swappable-battery-black.svg');
        }
      }

      &--green {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929942/value-proposition/2020/swappable-battery-green.svg');
        }
      }

      &--white {
        &::before {
          background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1585929942/value-proposition/2020/swappable-battery-white.svg');
        }
      }
    }   
  }
}