@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  .scene-4 {
    &__lines {
      &--color,
      &--white {
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }
    &__map-marker {
      top: 5.5%;
      left: 70%;
      width: 5%;
    }
    &__beacon {
      top: 42%;
      left: 50%;
      width: 40%;
      transform: translate(-50%, -50%);
    }
  }
}