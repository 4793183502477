@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import url('https://use.typekit.net/kpk5yma.css');

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html, body {
	font-size: 16px; //Root Font Size and Default Web Size
}

body {
	@extend %default_font-family;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  letter-spacing: .5px;
}

p {
  font-size: 1.125rem;
  line-height: 1.5;
  letter-spacing: .4px;
}
ul, ol {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.67;
  letter-spacing: .4px;
  li {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.67;
    letter-spacing: .4px;
    list-style-type: none;
  }
}

a {
  color: $primary;
  text-decoration: none;
  &:hover {
    color: darken($primary, 5%);
  }
}