@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  .scene-2 {
    &__device-1 {
      // LPR Pistol Grip
      width: 40%;
      left: -5%;
      bottom: 14%;
    }

    &__device-2 {
      // Mobile Printer
      width: 18.75%;
      left: 18%;
      bottom: 12%;
    }

    &__device-3 {
      // QPPRO+
      width: 12.5%;
      left: 31%;
      bottom: 23%;
    }

    &__device-4 {
      // LPR 13/14
      width: 21.25%;
      left: 38%;
      bottom: 32%;
    }

    &__device-5 {
      // LPG Pixel
      width: 20.625%;
      left: 49%;
      bottom: 29%;
    }

    &__device-6 {
      // HaloRing
      width: 16.25%;
      left: 41%;
      bottom: 18%;
    }

    &__device-7 {
      // Android Kiosk
      width: 31.25%;
      left: 59%;
      bottom: 19%;
    }

    &__device-8 {
      // Apple Kiosk
      width: 25%;
      left: 70%;
      bottom: 6%;
    }
  }
}