@import "../../stylesheets/variables";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.cp_testimonial-slider {
    .carousel {
        &-item {
            border-radius: 1rem;
        }
        &-caption {
            text-align: center;
            padding-left: 15%;
            padding-right: 15%;
            @include media-breakpoint-down(lg) {
                right: 0;
                left: 0;
            }
            @include media-breakpoint-up(lg) {
                padding-left: 0;
                padding-right: 0;
            }
            p {
                max-width: 600px;
                margin-left: auto;
                margin-right: auto;

            }
            p.quote {
                @include media-breakpoint-up(md) {
                    display: flex;
                    align-items: center;
                    height: 180px;
                }
            }
            .logo {
                margin-bottom: 2rem;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}