@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  .scene-5 {
    &__video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    &__android-logo {
      position: absolute;
      width: 45%;
      bottom: 14%;
      left: 50%;      
      transform: translate(-50%, 180%);
      @include media-breakpoint-up(lg) {
        bottom: 7%;
      }
    }
  }
}