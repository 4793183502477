@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  .scene-8 {
    &__rapid-dropoff-station {
      top: 20%;
      left: 5%;
      width: 33%;
    }
    &__apple-kiosk {
      top: 18%;
      right: 5%;
      width: 40%;
    }
    &__apple-logo {
      top: 80%;
      right: 18%;
      width: 12%;
    }
    &__android-kiosk {
      top: 25%;
      right: 5%;
      width: 38%;
    }
    &__android-logo {
      top: 88%;
      right: 14%;
      width: 20%;
    }
  }
}