@import '../../../stylesheets/variables';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.nav-container {
	margin-top: 50px;
	height: 0;
	opacity: 0;
	overflow: hidden;
	transition: height 500ms ease-in;

	@include media-breakpoint-up(lg) {
		height: 72px;
		margin-top: -72px;
		opacity: 1;
	}
}

.nav-logo,
nav {
	width: 260px;
	margin: auto;
}

nav {

	&.desktop {
		@include media-breakpoint-up(lg) {
			display: flex;
			width: 530px;
			text-align: center;
			justify-content: space-between;
		}

		@include media-breakpoint-up(xl) {
			width: 640px;
		}

		&::before {
			@include media-breakpoint-up(lg) {
				position: absolute;
				content: '';
				top: 72px;
				left: 0;
				right: 0;
				width: 100%;
				height: 0;
				z-index: 2;
				transition: height 500ms ease-in;
			}
		}

		&.active {
			&::before {
				@include media-breakpoint-up(lg) {
					height: 300px;
					transition: height 500ms ease-out;
				}

				@include media-breakpoint-up(xl) {
					height: 300px;
				}
			}
		}

		.nav-seperator {
			width: 100%;
			margin: 2rem 0 1rem;
			border-top: 1px solid #242424;
		}

		.tab {
			border-bottom: 1px solid #242424;

			&:last-of-type {
				border-bottom: 0;
			}

			@include media-breakpoint-up(lg) {
				display: inline-block;
				border: 0;
				vertical-align: top;
			}

			.tab-header {
				position: relative;
				display: block;
				@extend %default_font-family;
				font-size: 1.25rem;
				font-weight: 700;
				letter-spacing: .5px;
				padding: 1.1rem 0;
				cursor: pointer;

				@include media-breakpoint-up(lg) {
					display: inline-block;
					width: 90px;
					height: 72px;
					padding-top: 24px;
					font-size: 1.125rem;
				}

				&.hasSubNav {
					@include media-breakpoint-up(lg) {
						width: 110px;
					}

					&::after {
						position: absolute;
						content: '';
						right: 0;
						width: 32px;
						height: 32px;
						@include media-breakpoint-up(lg) {
							position: relative;
						}
					}

					span {
						position: relative;

						@include media-breakpoint-up(lg) {
							padding-right: 14px;
						}

						&:after {
							@include media-breakpoint-up(md) {
								top: 5px;
							}

							@include media-breakpoint-up(lg) {
								position: absolute;
								content: '';
								width: 16px;
								height: 16px;
								right: -4px;
								// Chrome specific rule
								@media screen and (min-resolution:.001dpcm) {
									top: 1px
								}

							}
						}
					}
				}
			}

			.subnav {
				height: 0;
				opacity: 0;
				overflow: hidden;

				@include media-breakpoint-up(lg) {
					position: absolute;
					display: flex;
					top: 72px;
					left: 0;
					right: 0;
					justify-content: center;
					z-index: 2;
				}

				.subnav-wrap {
					@include media-breakpoint-up(lg) {
						display: flex;
						width: auto;
						height: auto;
						margin: 0 auto;
						padding: 2rem 0 20px;
						text-align: left;
						justify-content: center;
					}

					&.active-featured-event {
						@include media-breakpoint-up(lg) {
							.nav-feature {
								width: auto;
								padding-right: 2rem;
								margin-right: 2rem;
								border-right: 1px dashes #fff;

								.nav-feature-img-wrap {
									height: 166px;
								}

								.nav-feature-text-wrap {
									position: absolute;
									bottom: 0;
									left: 0;
									width: 100%;
									transform: translateY(-14px);
									background-color: rgba($color: #000000, $alpha: .6);
									padding: .5rem;

									img.arrow {
										right: .5rem;
									}
								}
							}

							.subnav-container {
								margin-right: 2rem;
								padding-right: 2.1rem;
							}
						}
					}
				}

				.nav-feature {
					position: relative;
					width: 100%;
					margin-top: .5rem;
					margin-bottom: 2rem;
					@extend %default_font-family;

					@include media-breakpoint-up(lg) {
						width: auto;
						margin-top: 0;
						margin-bottom: 0;
						order: 2;
					}

					.nav-feature-container {
						display: flex;
						left: 0;

						@include media-breakpoint-up(lg) {
							display: block;
						}
					}

					.nav-feature-link {
						position: relative;
						display: flex;
						color: var(--bs-body-color);

						@include media-breakpoint-up(lg) {
							margin: auto;
							display: block;
							width: auto;
						}

						.nav-feature-img-wrap {
							@include media-breakpoint-up(lg) {
								display: inline-block;
								height: 121px;
								width: 192px;
								margin-bottom: 1.562rem;
								border-radius: .25rem;
								overflow: hidden;
							}
						}

						.nav-feature-img-thumbnail {
							width: 60px;
							height: 60px;
							margin: auto 1rem auto 0;
							float: left;

							@include media-breakpoint-up(lg) {
								width: 100%;
								height: auto;
								border-radius: 0 !important;
							}
						}

						.nav-feature-text-wrap {
							margin: auto 0;
							padding-right: 1.875rem;

							@include media-breakpoint-up(lg) {
								position: relative;
								display: flex;
								align-items: center;
							}

							.nav-feature-text {
								display: inline-block;
								font-size: .9rem;
								width: 100%;
								max-width: 150px;

								@include media-breakpoint-up(lg) {
									max-width: 160px;
									vertical-align: middle;
									color: var(--bs-body-color);
								}

								.nav-feature-text-title {
									font-size: .8rem;
									font-weight: 700;
									letter-spacing: .1rem;
									text-transform: uppercase;
									color: var(--bs-body-color);
								}
							}

							img.arrow {
								position: absolute;
								width: 20px;
								top: 35%;
								right: 0;

								@include media-breakpoint-up(lg) {
									top: initial;
								}
							}
						}
					}
				}

				.nav-featuredEvent {
					order: 3;

					.nav-featuredEvent-img-wrap {
						margin-bottom: 2.3rem;

						@include media-breakpoint-up(lg) {
							width: 250px;
							margin-bottom: 0;
						}

						img {
							width: 100%;
						}
					}
				}

				.subnav-container {
					@include media-breakpoint-up(lg) {
						width: auto;
						display: flex;
						margin-top: 0 !important;
						border-right-width: 1px;
						border-right-style: solid;
						margin-right: 3.5rem;
						padding-right: 3.5rem;
						order: 1;
					}

					.subnav-wrap {
						@include media-breakpoint-up(lg) {
							padding-top: 5px;
						}
					}

					.subnav-section {
						display: inline-block;
						min-width: 44%;
						margin-bottom: 1.5rem;
						vertical-align: top;

						&:nth-child(2n + 1) {
							margin-right: 10%;
						}

						@include media-breakpoint-up(lg) {
							width: auto;
							min-width: 175px;
							margin-right: 40px !important;
							margin-bottom: 0;
						}

						&:last-of-type {
							@include media-breakpoint-up(lg) {
								margin-right: 0 !important;
							}
						}
					}

					.subnav-header {
						@extend %default_font-family;
						color: var(--bs-body-color);
						vertical-align: top;
						font-size: 14px;
						font-weight: 400;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.6;
						letter-spacing: .3px;
					}

					div.subnav-header {
						border-bottom-width: 1px;
						border-bottom-style: solid;
						padding-bottom: .6rem;
						margin-bottom: .7rem;
					}

					a.subnav-header {
						overflow: hidden;

						img {
							display: none;

							@include media-breakpoint-up(lg) {
								display: block;
								width: 80px;
								height: 80px;
								margin: auto auto 20px;
								padding: 10px;
								border-radius: 50%;

								&.nav-industries-healthcare {
									background-color: darken($color: $healthcare, $amount: 45%);
									transition: background-color 500ms ease;
								}

								&.nav-industries-hospitality {
									background-color: darken($color: $hospitality,
											$amount: 45%);
									transition: background-color 500ms ease;
								}

								&.nav-industries-retail {
									background-color: darken($color: $retail, $amount: 40%);
									transition: background-color 500ms ease;
								}

								&.nav-industries-supplychain {
									background-color: darken($color: $supplychain,
											$amount: 45%);
									transition: background-color 500ms ease;
								}

								&.nav-industries-travel {
									background-color: darken($color: $travel, $amount: 55%);
									transition: background-color 500ms ease;
								}
							}
						}

						&:hover {
							@include media-breakpoint-up(lg) {
								.nav-industries-healthcare {
									background-color: darken($color: $healthcare, $amount: 25%);
								}

								.nav-industries-hospitality {
									background-color: darken($color: $hospitality,
											$amount: 25%);
								}

								.nav-industries-retail {
									background-color: darken($color: $retail, $amount: 20%);
								}

								.nav-industries-supplychain {
									background-color: darken($color: $supplychain,
											$amount: 25%);
								}

								.nav-industries-travel {
									background-color: darken($color: $travel, $amount: 25%);
								}
							}
						}
					}

					.subnav-links {
						a {
							display: block;
							margin-bottom: .5rem;
							color: var(--bs-body-color);
							font-size: 16px;
							font-weight: 400;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.67;
							letter-spacing: .4px;
						}
					}

					.subnav-links__indented {
						padding-left: 1.5rem;
					}

					&.no-border-right {
						margin-right: 0;
						padding-right: 0;
						border-right: 0;
					}
				}
			}

			&.active {
				.subnav {
					height: auto;
					padding-bottom: 1rem;
					opacity: 1;
					overflow: visible;

					@include media-breakpoint-up(lg) {
						height: 300px;
						padding: 0;
					}
				}
			}

			// override for stubborn css style
			&.nav-products {
				.nav-feature-img-thumbnail {
					@include media-breakpoint-up(lg) {
						margin: 0;
						margin-top: -1px !important;
					}
				}
			}

			&.nav-industries .subnav .subnav-container .subnav-section {
				display: block;
				margin-bottom: 1rem;

				@include media-breakpoint-up(xl) {
					display: inline-block;
				}
			}
		}

		.nav-products {
			.nav-feature {
				&:first-of-type {
					@include media-breakpoint-up(lg) {
						padding-right: 3.5rem;
						width: auto;
						border-right-style: solid;
						border-right-width: 1px;
					}
				}

				&:nth-of-type(2) {
					@include media-breakpoint-up(lg) {
						margin-left: 3.5rem;
					}
				}
			}
		}
	}

	&.mobile {
		padding-left: 15px;
		padding-right: 15px;
		max-width: 400px;
		margin: auto;
		margin-top: 2rem;

		.nav {
			&-logo {
				margin: auto;
				width: 100%;
				display: flex;
				justify-content: center;
				margin-bottom: 2rem;

				a {
					margin: auto;
				}
			}
		}

		.tab {
			border-bottom-width: 1px;
			border-bottom-style: solid;
			&:last-of-type {
				border-bottom: 0;
			}

			&-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 1.25rem;
				font-weight: 700;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.44;
				letter-spacing: .4px;
				text-align: center;
				padding: 1.1rem 0;
				cursor: pointer;

				img {
					width: 32px;
					height: 32px;
					transform: rotate(0deg);

					&.active {
						transition: transform .3s ease-in-out;
						transform: rotate(180deg);
					}
				}
			}

			.nav {
				&-logo {
					margin: auto;
					width: 100%;
				}

				&-feature {
					position: relative;
					width: 100%;
					margin-top: .5rem;
					margin-bottom: 2rem;
					display: inherit;
					flex-grow: 2;
					@extend %default_font-family;

					&-container {
						display: flex;
						left: 0;
					}

					&-link {
						position: relative;
						display: flex;
						color: var(--bs-body-color);

						.nav-feature-img-thumbnail {
							width: 60px;
							height: 60px;
							margin: auto 1rem auto 0;
							float: left;
						}

						.nav-feature-text-wrap {
							margin: auto 0;
							padding-right: 1.875rem;

							.nav-feature-text {
								display: inline-block;
								font-size: .9rem;
								width: 100%;
								max-width: 150px;

								.nav-feature-text-title {
									font-size: .8rem;
									font-weight: 700;
									letter-spacing: .1rem;
									text-transform: uppercase;
									color: var(--bs-body-color);
								}
							}

							img.arrow {
								position: absolute;
								width: 20px;
								top: 35%;
								right: 0;
							}
						}
					}
				}
			}

			.subnav {
				&-wrap {
					display: flex;
					flex-wrap: wrap;
				}

				&-container {
					flex-grow: 0;
					display: flex;
					flex-basis: 100%;
				}

				&-section {
					width: 100%;
					margin-bottom: 1.5rem;
				}

				&-header {
					@extend %default_font-family;
					color: var(--bs-body-color);
					vertical-align: top;
					font-size: 14px;
					font-weight: 400;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.6;
					letter-spacing: .3px;
					width: 100%;
				}

				.subnav-header {
					border-bottom-width: 1px;
					border-bottom-style: solid;
					padding-bottom: .6rem;
					margin-bottom: .7rem;
					overflow: hidden;
				}

				&-links {
					a {
						display: block;
						margin-bottom: .9375rem;
						color: var(--bs-body-color);
						font-size: 16px;
						font-weight: 400;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.67;
						letter-spacing: .4px;
					}

					&__indented {
						padding-left: 1.5rem;
					}
				}
			}
		}
	}
}

[data-bs-theme="light"] {
	nav {
		&.desktop {
			&::before {
				background-color: rgba(255,255,255, 0.93);
			}
		}
		.tab, .subnav-header {
			border-bottom-color: rgba($color: #000000, $alpha: .2);
			@include media-breakpoint-up(lg) {
				border-bottom-color: rgba($color: #000000, $alpha: .1);
			}
		}
		.tab {
			&-header {
				color: var(--bs-dark);
				&.hasSubNav {
					span {
						@include media-breakpoint-up(lg) {
							&::after {
								background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/down-chevron-black.svg');
							}
						}
					}
				}
			}
			&.active {
				.tab-header.hasSubNav {
					span::after {
						@include media-breakpoint-up(lg) {
							background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/up-chevron-black.svg');
						}
					}
				}
			}
		}
		.subnav-container {
			@include media-breakpoint-up(lg) {
				border-right-color: rgba($color: #000000, $alpha: .1);
			}
		}
		.nav-products {
			.nav-feature {
				&:first-of-type {
					@include media-breakpoint-up(lg) {
						border-right-color: rgba($color: #000000, $alpha: .1);
					}
				}
			}
		}
	}
}
[data-bs-theme="dark"] {
	nav {
		&.desktop {
			&::before {
				background-color: rgba(0, 0, 0, 0.93);
			}
		}
		.tab, .subnav-header {
			border-bottom-color: rgba($color: #ffffff, $alpha: .15);
		}
		.tab {
			&-header {
				color: var(--bs-white);
				&.hasSubNav {
					span {
						@include media-breakpoint-up(lg) {
							&::after {
								background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/down-chevron-white.svg');
							}
						}
					}
				}
			}
			&.active {
				.tab-header.hasSubNav {
					span::after {
						@include media-breakpoint-up(lg) {
							background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/up-chevron-white.svg');
						}
					}
				}
			}
		}
		.subnav-container {
			@include media-breakpoint-up(lg) {
				border-right-color: rgba($color: #ffffff, $alpha:.15);
			}
		}
		.nav-products {
			.nav-feature {
				&:first-of-type {
					@include media-breakpoint-up(lg) {
						border-right-color: rgba($color: #ffffff, $alpha:.15);
					}
				}
			}
		}
	}
}