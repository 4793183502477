* {
  box-sizing: border-box;
}

:root {
	--bs-border-radius: .5rem
}

html,
body {
  position: relative;
  height: 100%;
}

// theme bootstrap overrides
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
	padding-left: 1rem;
	padding-right: 1rem;
}

.container {
	@include media-breakpoint-up(xl) {
		max-width: 1200px;
	}
}

.layout {
	// blocks
	.site-banner {
		.alert {
			margin: 0;
		}
	}

	.intro-sec {
		padding-bottom: 2rem;
		margin-top: 4rem;
		@include media-breakpoint-up(md) {
			margin-bottom: 3rem;
		}

		@include media-breakpoint-up(lg) {
			margin-bottom: 5rem;
		}

		.headline {
			font-size: 1.7rem;
			margin-bottom: 2.5rem;

			@include media-breakpoint-up(md) {
				margin-bottom: 3rem;
			}

			@include media-breakpoint-up(lg) {
				font-size: 2rem;
			}
		}

		// value prop icons
		img {
			width: 100%;
			max-width: 88px;
		}

		p {
			margin: 0 auto;
		}
	}

	.video-sec {
		.container-fluid {
			position: relative;
			background-position: left top;
			background-size: cover;
			background-repeat: no-repeat;
			margin-bottom: 3rem;

			&:before {
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-image: linear-gradient(to bottom,
						rgba(0, 0, 0, 0.3),
						rgba(0, 0, 0, 0.5) 83%,
						rgba(0, 0, 0, 0.82));
			}

			.row {
				height: 50vh;

				@include media-breakpoint-up(lg) {
					height: 60vh;
				}
			}
		}

		.video-play-icon {
			width: 80px;
			height: 80px;
			padding: 5px;
			border: 1px solid #ffffff;
			border-radius: 50%;
			cursor: pointer;
		}

		.player-wrapper {
			margin-bottom: 3rem;
		}
	}

	.cta-hero {
		color: var(--bs-light);
		min-height: 50vh;
	}

	.article-cards {

		a {
			color: var(--bs-body-color);

			@include media-breakpoint-up(md) {
				margin-right: 5%;
			}

			&:nth-child(3n) {
				@include media-breakpoint-up(md) {
					margin-right: 0;
				}
			}

			&:hover {
				.card {
					.card-img-wrapper {
						&::before {
							background-color: rgba(0, 0, 0, 0.1) !important;
						}

						img {
							transform: scale(1.1);
						}
					}

					.btn-link-primary {
						&::after {
							width: 100%;
						}
					}
				}
			}

			.card {
				width: 100%;
				margin: 0 auto;
				height: 100%;
				padding-bottom: 2.5rem;
				border-radius: .5rem;
				overflow: hidden;

				.card-img-wrapper {
					position: relative;
					background-size: cover;
					background-position: center;
					height: 250px;
					overflow: hidden;

					&::before {
						position: absolute;
						content: '';
						width: 100%;
						height: 100%;
						background-color: rgba(0, 0, 0, 0);
						z-index: 1;
						transition: all ease 0.35s;
					}

					img {
						transition: all 0.35s cubic-bezier(0.3, 0.67, 0.24, 0.86);
					}
				}

				.card-body {
					position: relative;
					border-top: 3px solid $primary;
					border-bottom-left-radius: .25rem;
					border-bottom-right-radius: .25rem;

					.card-title {
						padding: 0;
						padding-top: .5rem;
						margin: auto;
						padding: 1.5rem 6px 0 0;
					}

					.card-date {
						position: absolute;
						top: -72px;
						left: 0;
						width: 60px;
						height: 69px;
						background-color: #000000;
						color: #ffffff;
						padding-top: .9rem;
						text-align: center;

						&::after {
							position: absolute;
							content: '';
							top: 0;
							right: -13px;
							width: 13px;
							height: 69px;
							background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1597707435/partials/blog-date-bg.svg');
							background-position: top right;
							background-repeat: no-repeat;
							background-size: cover;
						}

						.card-date-month,
						.card-date-day {
							@extend %default_font-family;
							font-weight: 700;
						}

						.card-date-month {
							margin-bottom: -5px;
							font-size: .9rem;
							text-transform: uppercase;
						}

						.card-date-day {
							font-size: 1.5rem;
							color: $primary;
						}
					}
				}

				.btn {
					position: absolute;
					right: 1.5rem;
					bottom: 1rem;
				}
			}
		}

		.pagination {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.badgeContainer {
		margin-bottom: .7rem;
	}

	.quotes-sec {
      @extend %default_font-family;
      position: relative;
      padding: 100px 0 50px;
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 70px;
        bottom: -69px;
        left: 0;
        z-index: -1;
        background-image: url("https://res.cloudinary.com/ipcmobile/image/upload/v1597707436/partials/products-page-quote-sec-infinite-peripherals.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        @include media-breakpoint-up(lg) {
          height: 100px;
          bottom: -100px;
        }
      }
      cite {
        font-style: normal;
      }
			.quote-icon {
				width: 88px;
				height: 88px;
			}
      .quote {
        font-size: 1.125rem;
				font-stretch: normal;
				font-style: normal;
				font-weight: 400;
				letter-spacing: .4px;
				line-height: 1.67;
				margin-bottom: 2rem;
				text-align: center;
      }
      .quote-title {
				font-size: 1.125rem;
				font-stretch: normal;
				font-style: normal;
				font-weight: 700;
				letter-spacing: 1px;
				line-height: 1.22;
				margin-bottom: 0.5rem;
				text-align: center;
				text-transform: uppercase;
        color: $primary;
      }
      .quote-name {
        font-size: 1.125rem;
        letter-spacing: -0.5px;
				font-weight: 700;
				letter-spacing: .4px;
    		line-height: 1.44;
      }
}

	.showcase-sec {
		.app-icon {
			width: 72px;
		}

		.showcase-apps {
			display: table;
		}
	}

	.contact-info-cards-sec {
		@extend %default_font-family;

		.contact-info-card {
			padding: 1rem;
			border-width: 0;

			.contact-info-card-title {
				margin: 0;
				margin-bottom: 1.5rem;
				font-size: 1.5rem;
			}

			.contact-info-div {
				margin-bottom: .1rem;

				.contact-info-sec-div {
					display: inline-block;
					margin-right: 30px;
					vertical-align: top;

					&:last-of-type {
						margin-right: 0;
					}
				}

				.contact-info-card-subtitle {
					margin: 0;
					margin-bottom: .3rem;
					text-transform: uppercase;
					font-size: .875rem;
				}

				&.email {
					.contact-info-sec-div {
						display: block;
						margin: 0;
					}
				}

				&.main-number,
				&.toll-free {
					.contact-info-sec-div {
						width: 150px;
					}
				}
			}
		}
	}

	.cornercut {
		&__pad-left {
			margin-left: .5rem;
			margin-bottom: 1rem;

			@include media-breakpoint-up(md) {
				margin-left: 1.5rem;
				margin-bottom: 1rem;
			}
		}

		&__pad-right {
			margin-right: .5rem;
			margin-bottom: 1rem;

			@include media-breakpoint-up(lg) {
				margin-right: 1.5rem;
				margin-bottom: 1rem;
			}
		}

		.position-sticky {
			top: 2rem;
		}
	}

	.appDownloadBanner {
		color: var(--bs-body-color);
		min-height: 15.5rem;

		@include media-breakpoint-up(sm) {
			border-radius: .5rem;
		}

		.appIconSec {
			@include media-breakpoint-up(lg) {
				padding-left: 1rem;
				margin: auto;

				&:hover {
					.appIcon {
						box-shadow: 0 8px 11px 0 rgba(0, 0, 0, 0.4);
					}
				}
			}
		}

		.appIconWrapper {
			margin-bottom: 2rem;

			@include media-breakpoint-up(lg) {
				margin-bottom: 0;
			}
		}

		.appIcon {
			display: block;
			width: 5.5rem;
			height: 5.5rem;
			border-radius: 1rem;
			box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.3);
			transition: all 300ms ease;
			margin-bottom: 1rem;
		}

		.appStoreIcon {
			display: block;
			width: 5.5rem;
		}

		.appName {
			display: block;
			margin-bottom: .7rem;
			height: 1.375rem;
			font-size: 1.125rem;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: 1.13px;
			color: rgba(255, 255, 255, 0.8);
		}

		.appTagline {
			font-size: 1.75rem;
			font-stretch: normal;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: normal;

			@include media-breakpoint-up(lg) {
				font-size: 2rem;
			}
		}

		.appContent {
			@include media-breakpoint-up(lg) {
				border-left: 1px dashed #fff;
				margin-left: 2rem;
				padding-left: 4rem;
			}
		}

		.appDescription {
			font-size: 1rem;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.67;
			letter-spacing: .4px;

			@include media-breakpoint-up(lg) {
				font-size: 1.125rem;
			}
		}

		.appCompatibility {
			@extend .appDescription;
			font-weight: 700;
			margin-bottom: 0;
		}
	}

	// elements
	// footer display
	&.industry,
	&.products {
		.intro-sec {
			.headline {
				margin-bottom: 2.5rem;
			}
		}

		.casestudies-sec,
		.announcements-sec {
			margin: 20px 0 0;
		}

		.casestudies-sec {
			padding: 100px 0;
		}

		.announcements-sec {
			padding: 50px 0;
		}

		.card-wrap {
			margin-bottom: 65px;

			@include media-breakpoint-up(md) {
				margin-bottom: 95px;
			}

			&:nth-child(3n + 2) {
				.card {
					@include media-breakpoint-up(lg) {
						margin: auto;
					}
				}
			}

			&:nth-child(3n) {
				.card {
					@include media-breakpoint-up(lg) {
						margin-left: auto;
					}
				}
			}
		}

		.download {
			@extend %default_font-family;
			letter-spacing: 1px;
			cursor: pointer;

			.download-text {
				color: #00000099;
			}

			.download-arrow {
				width: 15px;
				height: 15px;
			}
		}

		.top-solutions-sec {
			padding-top: 5rem;
			padding-bottom: 5rem;
			.card-body-wrap {
				@include media-breakpoint-up(md) {
					border: 1px solid var(--bs-border-color);
				}
			}
		}
	}

	&.blog,
	&.case-studies,
	&.partners,
	&.news,
	&.app-info-template,
	&.support {
		footer {
			margin-top: 120px;
		}
	}


	.card {
		--bs-card-border-radius: .5rem;
		--bs-card-inner-border-radius: .5rem;
		max-width: 325px;
		margin-left: auto;
		margin-right: auto;
		cursor: pointer;

		@include media-breakpoint-up(lg) {
			margin-left: inherit;
			margin-right: inherit;
		}

		.card-header {
			@extend %default_font-family;
			text-transform: uppercase;
			font-size: .75rem;
			font-weight: 700;
			letter-spacing: 1px;
			color: rgba(0, 0, 0, 0.6);
			border-radius: .5rem 0.5rem 0 0;
		}

		.card-body {
			.card-text {
				font-size: .8125rem;
				opacity: .6;
			}
		}
	}

	.sticky-footer {
		main {
			min-height: 100%;
			margin-bottom: -285px;
		}
	}

	.accordions {
		.accordion {
			padding: 30px 0;

			.header {
				cursor: pointer;
			}

			.accordion-title {
				padding-right: 50px;
			}

			.col {
				position: relative;
			}

			.icon-animation.plus {
				position: absolute;
				top: 4px;
				right: 20px;

				div {
					background-color: #242424;
				}
			}
		}

		.accordion-collapse {
			visibility: hidden;
			height: 0;
			opacity: 0;
			overflow: hidden;
			transition: all 500ms ease-in-out 100ms;

			&.show {
				visibility: visible;
				height: auto;
				opacity: 1;
				margin-top: 20px;
				overflow: visible;
			}
		}

		.accordion-collapsable {
			visibility: hidden;
			height: 0;
			opacity: 0;
			overflow: hidden;
			transition: all 500ms ease-in-out 100ms;

			.active {
				visibility: visible;
				height: auto;
				opacity: 1;
				margin-top: 20px;
				overflow: visible;
			}
		}

		.devices {
			.device-img {
				background-color: #ffffff;
			}
		}

		.btn.btn-light {
			background-color: #ffffff;
		}
	}

	.seperator {
		border-top: 2px solid $light-gray;
	}

	// Modals
	.modal {
		.icon {
			position: fixed;
			top: 32px;
			right: 32px;
			width: 30px;
			height: 30px;
			@include media-breakpoint-up(lg) {
				width: 40px;
				height: 40px;
			}
		}
		.btn-close {
			width: 0;
			height: 0;
			padding: 0;
			background: none;
			border-radius: 0;
			opacity: 1;
		}
	}

	.form-modal {
		background-size: cover;
		padding: 0 !important;

		.modal-body {
			position: relative;
			padding: 24px;
		}

		.modal-content {
			border: 0;
			h4 {
				text-transform: none;
				letter-spacing: normal;
			}
		}

		.spinner-border {
			top: 45%;
			left: 45%;
			z-index: 1;
		}

		iframe {
			transition: height 1s cubic-bezier(0.4, 0, 0.2, 1);
			z-index: 2;
		}

		.modal-backdrop {
			background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,c_scale,w_300/v1/partials/infinite-peripherals-contact-us-payment-scanning-printing.webp');
			background-size: cover;
			padding: 17px;

			@include media-breakpoint-up(sm) {
				background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,c_scale,w_600/v1/partials/infinite-peripherals-contact-us-payment-scanning-printing.webp');
			}

			@include media-breakpoint-up(md) {
				background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,c_scale,w_800/v1/partials/infinite-peripherals-contact-us-payment-scanning-printing.webp');
			}

			@include media-breakpoint-up(lg) {
				background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,c_scale,w_1000/v1/partials/infinite-peripherals-contact-us-payment-scanning-printing.webp');
			}

			@include media-breakpoint-up(xl) {
				background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/q_auto,f_auto,c_scale,w_2000/v1/partials/infinite-peripherals-contact-us-payment-scanning-printing.webp');
			}

			&.show {
				--bs-backdrop-opacity: 1;
			}
		}
	}

	.video-modal {
		.modal-dialog {
			max-width: fit-content;
		}

		.modal-content {
			// background-color: transparent;
			--bs-modal-bg: transparent;
			--bs-modal-border-width: 0;
		}

		.modal-body {
			padding: 0;
		}

		.modal-backdrop {
			&.show {
				opacity: .9;
			}
		}
	}

	//modifiers
	.sec-title {
		font-size: 2.25rem;
	}

	ul {
		list-style: none;
		padding-inline-start: 10px;

		li {
			position: relative;
			padding-left: 20px;

			&:before {
				position: absolute;
				content: '~';
				top: -5px;
				left: 0;
				font-size: 1.3rem;
			}
		}
	}

	.form-group {
		margin-bottom: 1.5rem;
	}

	label {
		display: none;
	}

	input[type='text'],
	input[type='email'],
	input[type='password'],
	textarea,
	select {
		background-color: #f2f2f2;
		border-color: #eeeeee;

		&:focus {
			background-color: var(--bs-white);
			border-color: #f0f0f0;
			box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.2);
		}

		&.isInvalid {
			background-color: #f8d7da !important;
		}

		&.isValid {
			background-color: #f3ffe0 !important;
		}
	}

	.carousel-control-prev-icon {
		background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/left-chevron-white.svg');
	}

	.carousel-control-next-icon {
		background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-chevron-white.svg');
	}

	.carousel-control-next-icon,
	.carousel-control-prev-icon {
		width: 35px;
		height: 35px;
	}

	.rounded-more {
		border-radius: 1rem;
	}

	// Backgroud Colors
	.low-em-bg {
		background-color: #e5e5e5;
		color: #242424;
	}

	.box-shadow {
		box-shadow: 0 4px 12px -1px rgba(0, 0, 0, 0.2);
	}

	.dotted-line {
		&-spaced {
			&--white {
				&::after {
					position: absolute;
					content: '';
					width: 100%;
					height: 10px;
					background-image: linear-gradient(to right, $white 30%, rgba(255, 255, 255, 0) 0%);
					background-position: top;
					background-size: 5px 1px;
					background-repeat: repeat-x;
				}
			}

			&--black {
				&::after {
					position: absolute;
					content: '';
					width: 100%;
					height: 10px;
					background-image: linear-gradient(to right, $black 30%, rgba(255, 255, 255, 0) 0%);
					background-position: top;
					background-size: 5px 1px;
					background-repeat: repeat-x;
				}
			}
		}
	}

	#cookieBanner {
		position: fixed;
		width: 94%;
		left: 3%;
		bottom: -310px;
		padding: 20px;
		background-color: #ffffff;
		color: $secondary;
		border-radius: .25rem;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
		transition: bottom 1s ease-in-out;
		z-index: 9999;
		@include media-breakpoint-up(lg) {
			max-width: 300px;
			left: 20px;
			right: auto;
			bottom: -270px;
		}
		p {
			line-height: 1.2;
		}
		button {
			color: #ffffff;
		}
		.btn {
			margin-bottom: 0;
		}
		&.show {
			bottom: 10px;
			@include media-breakpoint-up(lg) {
				bottom: 20px;
			}
		}
	}
}