@import '../../stylesheets/variables';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

header {

	&, .header-container {
		height: 50px;
		overflow: hidden;

		@include media-breakpoint-up(lg) {
			height: 72px;
		}
	}

	.header-container {
		width: 100%;
		transition: height 500ms ease-out;
		.header-wrap {
			margin-top: 9px;
			@include media-breakpoint-up(lg) {
				position: relative;
				display: flex;
				align-items: center;
				height: 72px;
				margin-top: 0;
			}
		}

		.site-logo {
			display: inline-block;

			a {
				position: relative;
				display: block;
				h1 {
					margin: 0;
				}
				img {
					width: 150px;
					height: 28px;

					@include media-breakpoint-up(lg) {
						width: 152px;
						height: auto;
					}
				}
			}

			&.icon-only {
				a {
					width: 28px;
					overflow: hidden;
					transition: width 0s 300ms;

					@include media-breakpoint-up(lg) {
						width: 152px;
					}

					&::before {
						opacity: 0;
						transition: opacity 500ms ease;
					}
				}
			}
		}

		.menu-icon {
			float: right;
			margin-top: 1px;
		}

		.theme-toggle-switch-2023 {
			position: relative;
			float: right;
			margin-right: 1.5rem;
			@include media-breakpoint-up(lg) {
				position: absolute;
				float: none;
				top: 30%;
				right: 100px;
				margin-right: 50px;
			}
		}

		.search-icon {
			float: right;
			margin-right: 24px;
			@include media-breakpoint-up(lg) {
				position: absolute;
				float: none;
				top: 30%;
				right: 5px;
			}
		}

		// .header-container active state
		&.active {
			position: fixed;
			height: 100vh;
			padding-bottom: 15vh;
			overflow: hidden;
			z-index: 3;

			.nav-container {
				height: auto;
				opacity: 1;
				overflow: visible;
				transition: all 500ms ease 200ms;
				transition: opacity 500ms ease;
				z-index: initial;
			}

		}
	}
}

[data-bs-theme="light"] {
	header {
		.header-container {
			background: rgba(255, 255, 255, 0.93);
		}
	}
}

[data-bs-theme="dark"] {
	header {
		.header-container {
			background: rgba(0, 0, 0, 0.93);
		}
	}
}