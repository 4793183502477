@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  .scene-11 {
    &__gradient-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(41.27% 52.32% at 65.78% 57.36%, #1F84E2 0%, #011F2E 100%);
    }
    &__processor {
      width: 10%;
      &.processor {
        &-1 {
          bottom: 10.3%;
          right: 24.2%;
        }
        &-2 {
          bottom: 50%;
          right: 53.8%;
        }
        &-3 {
          bottom: 68%;
          right: 33.4%;
        }
        &-4 {
          bottom: 34%;
          right: 1%;
        }
      }
    }
    &__server-rack {
      width: 10%;
      &.server-rack {
        &-1 {
          bottom: 44%;
          right: 30%;
        }
        &-2 {
          bottom: 36.5%;
          right: 22.5%;
        }
      }
    }
    &__vigilant {
      bottom: 10%;
      right: 45%;
      width: 18.2%;
    }
    &__cloud-upload {
      bottom: 58%;
      right: 7%;
      width: 22%;
    }
    &__data-stripes {
      bottom: 54%;
      right: 3%;
      width: 10%;
    }
    &__data-streams {
      width: 12%;
      &.streams {
        &-1 {
          bottom: 52.8%;
          right: 43.3%;
        }
        &-2 {
          bottom: 36.5%;
          right: 29%;
        }
      }
    }
    &__data-stream {
      width: 9%;
      &.stream {
        &-1 {
          bottom: 22%;
          right: 5%;
        }
        &-2 {
          bottom: 16.2%;
          right: 5.3%;
        }
        &-3 {
          bottom: 0.9%;
          right: 17%;
        }
      }
    }
  }
}