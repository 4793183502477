@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  .scene-13 {
    &__servers {
      width: 10%;
      transform: scaleX(-1);
      &.server {
        &-1 {
          top: 22.7%;
          left: 15.5%;
        }
        &-2 {
          top: 29.3%;
          left: 23.1%;
        }
        &-3 {
          top: 35.4%;
          left: 15.4%;
        }
      }
    }
    &__phone {
      top: 60%;
      left: 52%;
      width: 9%;
      transform: rotate(317deg);
    }
    &__cloud-database {
      top: 19%;
      left: 64%;
      width: 24%;
    }
    &__bolts {
      width: 3%;
      height: 6%;
      &.bolt {
        &-blue {
          &-1 {
            top: 90.36%;
            left: 61.8%;
            transform: translate(-50%, -50%) rotate(228deg);
          }
          &-2 {
            top: 88.8%;
            left: 67.7%;
            transform: translate(-50%, -50%) rotate(221deg);
          }
          &-3 {
            top: 83.7%;
            left: 74.2%;
            transform: translate(-50%, -50%) rotate(197deg);
          }
          &-4 {
            top: 75%;
            left: 77.3%;
            transform: translate(-50%, -50%) rotate(163deg);
          }
          &-5 {
            top: 63.4%;
            left: 77.4%;
            transform: translate(-50%, -50%) rotate(140deg);
          }
          &-6 {
            top: 53.7%;
            left: 75.7%;
            transform: translate(-50%, -50%) rotate(135deg);
          }
        }
        &-green {
          &-1 {
            top: 69%;
            left: 50.2%;
            transform: translate(-50%, -50%) rotate(323deg);
          }
          &-2 {
            top: 61%;
            left: 51%;
            transform: translate(-50%, -50%) rotate(331deg);
          }
          &-3 {
            top: 53.2%;
            left: 53.1%;
            transform: translate(-50%, -50%) rotate(347deg);
          }
          &-4 {
            top: 45.6%;
            left: 56.6%;
            transform: translate(-50%, -50%) rotate(12deg);
          }
          &-5 {
            top: 42.4%;
            left: 61.3%;
            transform: translate(-50%, -50%) rotate(33deg);
          }
          &-6 {
            top: 41%;
            left: 66%;
            transform: translate(-50%, -50%) rotate(47deg);
          }
        }
        &-red {
          &-1 {
            top: 31.3%;
            left: 59.4%;
            transform: translate(-50%, -50%) rotate(53deg);
          }
          &-2 {
            top: 31.3%;
            left: 54.8%;
            transform: translate(-50%, -50%) rotate(49deg);
          }
          &-3 {
            top: 31.3%;
            left: 48.5%;
            transform: translate(-50%, -50%) rotate(47deg);
          }
          &-4 {
            top: 31%;
            left: 43.4%;
            transform: translate(-50%, -50%) rotate(45deg);
          }
          &-5 {
            top: 26.7%;
            left: 36.3%;
            transform: translate(-50%, -50%) rotate(49deg);
          }
          &-6 {
            top: 27.7%;
            left: 30.4%;
            transform: translate(-50%, -50%) rotate(35deg);
          }
          &-7 {
            top: 31%;
            left: 24.9%;
            transform: translate(-50%, -50%) rotate(21deg);
          }
          &-8 {
            top: 35.8%;
            left: 20.1%;
            transform: translate(-50%, -50%) rotate(0deg);
          }
          &-9 {
            top: 32.7%;
            left: 38.2%;
            transform: translate(-50%, -50%) rotate(34deg);
          }
          &-10 {
            top: 35%;
            left: 33.5%;
            transform: translate(-50%, -50%) rotate(23deg);
          }
          &-11 {
            top: 37.4%;
            left: 28.9%;
            transform: translate(-50%, -50%) rotate(19deg);
          }
          &-12 {
            top: 39.5%;
            left: 38.8%;
            transform: translate(-50%, -50%) rotate(23deg);
          }
          &-13 {
            top: 44%;
            left: 33.5%;
            transform: translate(-50%, -50%) rotate(17deg);
          }
        }
      }
    }
  }
}