@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  .cloud-aas__logos {
    position: absolute;
    top: 25%;
    left: 15%;
    width: 11%;
    img {
      position: absolute;
      left: 50%;
      width: auto;
      height: 9vw;
      max-height: 200px;
      
    }

    .aws-logo {
      transform: translate(-50%, 0%);
    }

    .azure-logo {
      transform: translate(-50%, 140%);
    }

    .gcloud-logo {
      transform: translate(-50%, 280%);
    }

  }
}