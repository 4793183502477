[data-bs-theme="light"] {
  --bs-body-color: #2a2a2a;

  .layout {
    .quotes-sec {
      background-color: #fafafa;
    }
    &.industry,
    &.products {
      .top-solutions-sec {
        .card-body-wrap {
          --bs-border-color: #f6f6f6;
        }
      }
    }
    .card {
      --bs-card-border-color: #f2f2f2;
    }
  }

}