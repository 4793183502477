@import '../../stylesheets/variables';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.layout .badge {
    // bootstrap reset
    display: inline-block;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    text-align: left;
    
    // custom css
    height: 2.5rem;
    padding: .25rem 0.625rem 0.25rem 0.25rem;
    border-radius: 4px;
    margin-right: .5rem;
    user-select: none;

    &:last-of-type {
      margin-right: 0;
    }

    &.badge-dark {
      background-color: #242424;
      color: var(--bs-body-color);
    }
    
    &.small {
      display: inline-flex;
      align-items: center;
      max-height: 1.25rem;
      margin-bottom:.5rem;
      padding: .7rem .3rem;
      font-size: .8rem;
      letter-spacing: .3px;
    }

    &.showMore {
      cursor: pointer;
      &:hover {
        background-color: #242424;
        color: var(--bs-body-color);
      }
    }
}