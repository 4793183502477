@import '../../stylesheets/variables';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

footer {
    background-color: var(--bs-body-bg);

    .subfooter {
        h3 {
            font-size: 200%;
        }

        .have-questions,
        .newsletter {
            position: relative;

            .content {
                position: relative;
                z-index: 1;
            }

            &::before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .have-questions {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 400px;
            color: var(--bs-dark);
            background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1707433027/partials/office-conference-table-chairs.webp');
            background-position: center bottom;
            background-repeat: no-repeat;
            background-size: cover;

            @include media-breakpoint-up(lg) {
                height: 500px;
            }

            &.single {
                padding: 8rem 15px;

                @include media-breakpoint-up(sm) {
                    margin-bottom: -20px;
                }

                @include media-breakpoint-up(lg) {
                    padding: 9rem 15px 11rem;
                    margin-bottom: auto;
                }

                .content {
                    max-width: 300px;
                    margin: 0 auto;
                }
            }

            .btn-link {
                text-decoration: none;
                color: var(--bs-dark);
                margin-bottom: 3rem;
            }
        }
    }

    .footer {
        &-nav {
            position: relative;
            width: 100%;
            padding-top: 3rem;
            @extend %default_font-family;

            &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-image: url('https://res.cloudinary.com/ipcmobile/image/upload/v1604906350/partials/grid-hex-white.webp');
                opacity: .45;
            }

            ul {
                padding-inline-start: 1rem;

                @include media-breakpoint-up(lg) {
                    padding-inline-start: 0;
                }

                li {
                    padding-left: 0;
                    margin-bottom: 1rem;

                    &::before {
                        display: none;
                    }
                }
            }

            &__branding {
                &__logo {
                    width: 181px;
                    margin-bottom: .75rem;

                    @include media-breakpoint-up(lg) {
                        width: 184px;
                    }
                }

                &__tagline {
                    font-size: 1.125rem;
                    font-weight: 600;
                    color: var(--bs-body-color);
                    line-height: 1.25;
                    opacity: .5;
                    margin-bottom: 2rem;
                }
            }

            &__social {
                margin-bottom: 3rem;
                @include media-breakpoint-up(lg) {
                    margin-bottom: 1rem;
                }
                &-icon {
                    margin-right: 1.5rem;

                    @include media-breakpoint-up(md) {
                        margin-right: 1rem;
                    }

                    img {
                        width: 30px;
                        height: 30px;
                        opacity: 1;
                    }
                }
            }

            &__content {
                position: relative;
                margin-bottom: 2.5rem;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }

                &.dotted-line-spaced--white {
                    &::after {
                        width: calc(100% - 2rem);
                        bottom: -24px;
                        left: 1rem;
                        opacity: .4;

                        @include media-breakpoint-up(lg) {
                            position: relative;
                            display: none;
                        }
                    }
                }
            }

            &__links {
                @include media-breakpoint-up(lg) {
                    display: flex;
                }

                &__subheading {
                    position: relative;
                    font-size: .9rem;
                    font-weight: 400;
                    color: var(--bs-body-color);
                    border-bottom: 1px solid var(--bs-body-color);
                    opacity: .4;

                    @include media-breakpoint-up(lg) {
                        opacity: .9;
                    }

                }

                &__link {
                    color: var(--bs-body-color);
                }

                &__ipcmobile {
                    @include media-breakpoint-up(lg) {
                        width: 160px;
                        margin-right: 70px;
                    }
                }

                &__solutions {
                    @include media-breakpoint-up(lg) {
                        flex: 1;
                    }

                    .footer-nav__links__container {
                        @include media-breakpoint-up(md) {
                            columns: 2;
                        }
                    }
                }
            }

            &__legal {
                flex-direction: column-reverse;
                color: var(--bs-body-color);
                font-size: 80%;
                padding-bottom: 30px;
                opacity: .6;

                @include media-breakpoint-up(md) {
                    display: flex;
                    flex-direction: row;
                    text-align: left;
                    opacity: .7;
                }

                &__copy {
                    text-align: center;

                    @include media-breakpoint-up(md) {
                        text-align: left;
                    }

                    @include media-breakpoint-up(lg) {
                        font-size: .9rem;
                        // color: rgba(255, 255, 255, 0.8);
                    }
                }

                &__links {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1rem;

                    @include media-breakpoint-up(lg) {
                        justify-content: flex-end;
                    }
                }

                &__link {
                    margin-right: 1.5rem;
                    color: var(--bs-body-color);

                    @include media-breakpoint-up(lg) {
                        margin-right: 2.5rem;
                        font-size: .9rem;
                    }

                    @include media-breakpoint-up(xl) {
                        margin-right: 4rem;
                    }

                    &:hover,
                    &:active {
                        color: var(--bs-body-color);
                        opacity: .5;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

[data-bs-theme="light"] {
    footer {
        .footer-nav {
            &::before {
                filter: invert(1);
            }
        }
    }
}