@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  .scene-12 {
    &__office-building {
      top: 41%;
      left: 36.4%;
      width: 22%;
    }
    &__cloud {
      top: 23.8%;
      left: 21.2%;
      width: 11%;
    }
    &__security {
      top: 17.2%;
      left: 54.4%;
      width: 14%;
    }
    &__payment-acceptance {
      top: 28.5%;
      left: 75.9%;
      width: 10.1%;
    }
    &__legacy {
      top: 60.5%;
      left: 61.7%;
      width: 7%;
    }
    &__mobile {
      top: 71.3%;
      left: 21.3%;
      width: 13%;
    }
    &__portal {
      top: 49%;
      left: 7.6%;
      width: 10.8%;
    }
    &__title {
      position: absolute;
      font-size: 1.5rem;
      font-weight: 700;
      color: var(--bs-white);
      text-transform: uppercase;
      @include media-breakpoint-up(lg) {
        font-size: 2.35rem;
      }
      &.title {
        &-1 {
          // Cloud
          top: 23%;
          left: 33.5%;
        }
        &-2 {
          // Security
          top: 21%;
          left: 68%;
        }
        &-3 {
          // Payment Acceptance
          top: 55.8%;
          left: 74.6%;
          text-align: center;
          line-height: 1.3;
        }
        &-4 {
          // Legacy
          top: 77.6%;
          left: 70.2%;
        }
        &-5 {
          // Mobile
          top: 80.7%;
          left: 36.9%;
        }
        &-6 {
          // Portal
          top: 64.2%;
          left: 7.7%;
        }
      }
    }
    &__data-lines {
      position: absolute;
      top: 56.8%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 68%;
    }
  }
}