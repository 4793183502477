@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

.cp_jumpad {
  .container {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  &__link {
    margin: .5rem 0;
    cursor: pointer;
  }
  &__img-container {
    margin-bottom: 1rem;
    transition: all .5s ease;
    &:hover {
      img {
        transform: scale(.9);
      }
      & + .cp_jumpad__text {
        color: var(--bs-primary);
      }
    }
    img {
      transform: scale(.8);
      transition: all .5s ease;
    }
  }
  &__text {
    font-size: 1.125rem;
  }
}

[data-bs-theme="light"] {
  .cp_jumpad {
    &__text {
      color: var(--bs-dark);
    }
  }
}

[data-bs-theme="dark"] {
  .cp_jumpad {
    &__text {
      color: var(--bs-light);
    }
  }
}