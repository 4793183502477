#patent-protected-disclaimer {
    p {
        font-size: .75rem;
        margin-bottom: 2rem;
    }
}

[data-bs-theme="light"] #patent-protected-disclaimer {
    hr, p {
        color: #aaaaaa;
    }
}

[data-bs-theme="dark"] #patent-protected-disclaimer {
    hr, p {
        color: #808080;
    }
}