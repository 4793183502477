@import '../../stylesheets/variables';
@import '../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  position: relative;
  width: 100%;
  height: 300px;
  z-index: 2;
  @include media-breakpoint-up(md) {
    height: 400px;
  }

  .start-screen, .scenes__scene-1 {
    div {
        font-size: 2rem;
        margin-bottom: .5rem;
      }
      
      img.logo {
        display: block;
        width: 250px;
        margin-bottom: 1.5rem;
      }
  }

  .start-screen {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    width: 100%;
    height: 100%;

    img.rotate-to-landscape {
      display: block;
      width: 100px;
      padding: 1.5rem;
      animation: pulse 2s infinite;
    }
  }

  &__scenes-container {
    position: fixed;
    display: none;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -5;
    &.fullscreen {
      display: flex;
      background-color: var(--bs-black);
      opacity: 1;
      z-index: 0;
  
      @media screen and (orientation: portrait) {
        display: none;
      }
    }
  }

  &__scenes {
    position: relative;
    width: 100%;
    max-width: 125rem;
    height: calc(100vw * .5625);
    max-height: calc(125rem * .5625);
    margin: 0 auto;
    background-color: var(--bs-secondary);
    overflow: hidden;
  }

  .scenes {
    height: 100vh;
    &__scene-2,
    &__scene-3,
    &__scene-4,
    &__scene-6,
    &__scene-7,
    &__scene-8,
    &__scene-9,
    &__scene-10,
    &__scene-11,
    &__scene-12,
    &__scene-13,
    &__scene-14, {
      img {
        position: absolute;
      }
    }
    &__scene-name {
      position: absolute;
      display: flex;
      align-items: center;
      gap: 1.5rem;
      font-size: 1.75rem;
      font-weight: 700;
      color: var(--bs-black);
       @include media-breakpoint-up(lg) {
        font-size: 2.5rem;
      }
      div:first-of-type {
        color: var(--bs-white);
        white-space: nowrap;
      }
      div:last-of-type {
        position: relative;
        height: 50px;
        background-color: var(--bs-white);
        border-radius: 5rem;
        text-align: center;
        overflow: hidden;
        @include media-breakpoint-up(lg) {
          height: 100px;
        }
        ul {
          font-size: inherit;
          font-weight: inherit;
          letter-spacing: inherit;
          padding-inline-start: inherit;
          margin-top: .3rem;
          margin-bottom: 0;
          padding: initial;
          line-height: 100px;
          margin-top: -25px;
          @include media-breakpoint-up(lg) {
            margin-top: -1px;
          }
          li {
              font-size: inherit;
              font-weight: inherit;
              letter-spacing: inherit;
              line-height: inherit;
              padding-left: 0;
              white-space: nowrap;
              &::before {
                display: none;
              }
            }
        }
      }
    }
    &__bg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    &__gradient-bg {
      position: absolute;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      background: radial-gradient(62.36% 62.36% at 50% 37.64%, #026ED1 0%, #094C63 50%, #000 100%);
    }
    &__close {
      position: absolute;
      top: 10%;
      right: 2.5vw;
      border: 0;
      font-size: 2rem;
      color: var(--bs-white);
      background-color: transparent;
      @include media-breakpoint-up(lg) {
        font-size: 2.5rem;
        top: 0;
        right: 2vw;
      }
    }
  }
}

[data-bs-theme="light"] .we-are-animation {
  background-color: var(--bs-light);
  .start-screen {
    color: var(--bs-secondary);
  }
  .start-screen {
    background-color: $light;
  }
}

[data-bs-theme="dark"] .we-are-animation {
  background-color: var(--bs-secondary);
  .start-screen {
    color: var(--bs-light);
  }
  .start-screen {
      background-color: var(--bs-secondary);
    }
}