@import "../../stylesheets/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.layout .cp_customer-stories {
    &__logos {
        .slick-slide img {
            @include media-breakpoint-up(lg) {
                width: 80%;
                opacity: .25;
                filter: grayscale(100%);
                transition: all 1s ease;
            }
        }
        .slick-current {
            img {
                filter: none;
                opacity: 1;
                @include media-breakpoint-down(lg) {
                    transform: translateX(0);
                    transition: all 100ms ease;
                }
                @include media-breakpoint-up(lg) {
                    transform: scale(1.1);
                }
            }
        }
        .slick-current ~ div {
            img {
                @include media-breakpoint-down(lg) {
                    transform: translateX(-35%);
                    filter: grayscale(100);
                    opacity: .25;
                }
            }
        }
        .slick-slide:has(+ .slick-current) {
            img {
                @include media-breakpoint-down(lg) {
                    transform: translateX(35%);
                    filter: grayscale(100);
                    opacity: .25;
                }
            }
        }
        .slick-arrow {
            &::before {
                color: transparent;
            }
        }
    }
    &__claims {
        .slick-track {
            display: flex;
        }
        .slick-slide {
            & > div,
            & > div > div,
            .container,
            .row {
                height: 100%;
            }
        }
    }
    h2,
    .h2 {
        font-size: 2.25rem;
    }

    h2 {
        margin-top: 5rem;
    }

    ul {
        padding-inline-start: 0;

        li {
            margin-bottom: 2.25rem;
            &::before {
                content: '';
                top: 0.5rem;
                width: .3125rem;
                height: 2rem;
                background-color: $primary;
            }

            p {
                font-size: 1rem;
                span {
                    &:first-of-type {
                        display: block;
                        font-weight: 700;
                        margin-bottom: 0;
                    }
                }
            }

        }
    }

    .bgImg {
        width: calc(100% + 2rem);
        margin-top: -1rem;
        margin-left: -1rem;
        @include media-breakpoint-up(lg) {
            position: absolute;
            width: 50vw;
            right: 0;
            bottom: 0;
            transform: translateX(100%);
        }
    }

}

[data-bs-theme="light"] {
    .layout .cp_customer-stories {
        .slick-arrow {
            &.slick-prev::before {
                background-image: url("https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/left-chevron-black.svg");
            }

            &.slick-next::before {
                background-image: url("https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-chevron-black.svg");
            }
        }
    }
}

[data-bs-theme="dark"] {
    .layout .cp_customer-stories {
        .slick-arrow {
            &.slick-prev::before {
                background-image: url("https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/left-chevron-white.svg");
            }

            &.slick-next::before {
                background-image: url("https://res.cloudinary.com/ipcmobile/image/upload/v1/icons/right-chevron-white.svg");
            }
        }
    }
}