@import '../../stylesheets/variables';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.layout.home {
    .hardware-banner {
        overflow: hidden;
        background: linear-gradient(to bottom right, $travel, $healthcare);

        @include media-breakpoint-up(lg) {
            height: 400px;
        }

        @include media-breakpoint-up(xl) {
            height: 500px;
        }

        @include media-breakpoint-up(xxl) {
            height: 600px;
        }

        .container,
        .row {
            @include media-breakpoint-up(lg) {
                height: 100%;
            }
        }

        .col {
            @include media-breakpoint-up(lg) {
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        &__content {
            @include media-breakpoint-up(lg) {
                width: 500px;
            }
        }

        h2 {
            @include media-breakpoint-up(lg) {
                // font-size: 2rem;
            }
        }

        img {
            transition: transform 1s ease;

            @include media-breakpoint-down(lg) {
                bottom: 0;
                width: 100%;
                // left: 50%;
                // transform: translateX(-50%);
            }

            @include media-breakpoint-up(lg) {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                transform: translateX(25%);
            }

            @media screen and (min-width: 1700px) {
                transform: translateX(0);
            }
        }
    }

    .software-banner {
        height: 600px;
        overflow: hidden;
        background: $black;

        .container,
        .row {
            @include media-breakpoint-up(lg) {
                height: 100%;
            }
        }

        .col {
            @include media-breakpoint-up(lg) {
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        &__content {
            @include media-breakpoint-up(lg) {
                width: 500px;
            }
        }

        h2 {
            @include media-breakpoint-up(lg) {
                // font-size: 2rem;
            }
        }

        img {
            @include media-breakpoint-up(lg) {
                position: absolute;
                top: 0;
                height: 100%;
            }
        }
    }

    .cp_jumpad {
        padding: 3rem 0;

        @include media-breakpoint-up(lg) {
            padding: 7rem 0;
        }

        a {
            padding: 0rem .5rem 2rem;
        }

        &__text {
            margin-top: -1rem;
        }
    }

    .hardware-jumppad {
        .container {
            @include media-breakpoint-up(lg) {
                grid-template-columns: repeat(5, 1fr);
            }
        }
    }

    .customer-stories {
        h2 {
            width: 90%
        }
    }

    .customer-testimonials {
        .carousel {
            &-item {
                overflow: hidden;
                @include media-breakpoint-down(lg) {
                    height: 800px;
                }
            }

            &-caption {
                @include media-breakpoint-down(md) {
                    position: relative;
                    margin-top: 4rem;

                }
                p.quote {
                    text-align: justify;
                }
            }
        }

        .background-image-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;

            &::before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, .2) 0%,
                    rgba(0, 0, 0, .7) 60%,
                    rgba(0, 0, 0, 1) 100%
                );
            }

            @include media-breakpoint-up(lg) {
                position: relative;
            }

            img {
                @include media-breakpoint-down(md) {
                    position: absolute;
                    bottom: 0;
                }
            }
        }

        .kpt, .oai, .ocfa {
            .background-image-wrapper {
                    img {
                        @include media-breakpoint-down(lg) {
                            left: 50%;
                            opacity: .5;
                            width: 200%;
                            max-width: initial;
                            transform: translateX(-50%);
                        }
                    }
                }
        }

        .kpt {
            .logo {
                height: 64px;
            }
        }

        .oai {
            .logo {
                width: 80%;
                @include media-breakpoint-up(lg) {
                    width: 300px;
                }
            }
        }

        .ocfa {
            .background-image-wrapper {
                &::before {
                    background: linear-gradient(
                    to bottom,
                    rgba(255, 0, 0, .4) 0%,
                    rgba(0, 0, 0, 1) 100%
                );
                }
            }
            .logo {
                height: 64px;

                @include media-breakpoint-up(lg) {
                    height: 80px;
                }
            }
        }
    }

    .announcements {
        padding: 5rem 0 8rem;

        @include media-breakpoint-up(lg) {
            padding: 5rem 0 10rem;
        }

        p {
            max-width: 600px;
        }
    }
}

[data-bs-theme="light"] {
    .layout.home {
        .cp_jumpad {
            a {
                background-color: $light-gray;
            }
        }

        .customer-stories {

        }
    }
}

[data-bs-theme="dark"] {
    .layout.home {
        .cp_jumpad {
            a {
                background-color: #1a1a1a;
            }
        }

        .customer-stories {

        }
    }
}