%container-24x24 {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
}

.icon-animation {
  &.menu {
    @extend %container-24x24;
    div {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $primary;
      border-radius: 10px;
      left: 0;
      &:nth-child(2) {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &:first-of-type {
        top: 25%;
      }
      &:last-of-type {
        top: 70%;
      }
    }
  }
  &.plus {
    @extend %container-24x24;
    div {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $primary;
      border-radius: 10px;
      left: 0;
      top: 50%;
      &:nth-child(2) {
        transform: rotate(90deg);
      }
      &:first-of-type,
      &:last-of-type {
        width: 30%;
      }
      &:last-of-type {
        transform: translate(240%, 0);
      }
    }
  }
  &.search {
    @extend %container-24x24;

    #search-icon {
      margin: -3px 0 0 -4px;
      fill: #ffffff;
    }
    input[type="text"] {
      width: 0;
      padding: 0;
      margin: 0;
    }
  }
}
/* FORWARD ANIMATION VALUES */
.active {
  .icon-animation {
    &.menu {
      div {
        &:nth-child(2) {
          animation: closeBarForwardTwo 1s ease-in-out;
          transform: rotate(315deg);
          margin: -2% 0 0 -50%;
          transition: rotate 1s ease-in-out;
        }
        &:first-of-type,
        &:last-of-type {
          width: 30%;
          transition: rotate 1s ease-in-out;
        }
        &:first-of-type {
          animation: closeBarForwardOne 1s;
          transform: rotate(225deg) translate(-240%, 100%);
        }
        &:last-of-type {
          animation: closeBarForwardThree 1s;
          transform: rotate(45deg) translate(-65%, -570%);
        }
      }
    }
    &.plus {
      @extend %container-24x24;
      div {
        &:nth-child(2) {
          animation: plusBarForwardTwo 1s ease;
          transform: rotate(-45deg);
        }
        &:first-of-type,
        &:last-of-type {
          width: 30%;
        }
        &:first-of-type {
          animation: plusBarForwardOne 0.9s ease-in-out;
          transform: rotate(225deg) translate(-200%, 250%);
        }
        &:last-of-type {
          animation: plusBarForwardThree 0.9s ease-in-out;
          transform: rotate(225deg) translate(30%, 250%);
        }
      }
    }
    &.search {
      width: auto;
    }
  }
}
/* BACKWARD ANIMATION VALUES */
.inactive {
  .icon-animation {
    &.menu {
      div {
        &:first-of-type {
          animation: closeBarBackwardOne 1s ease-in-out;
        }

        &:nth-child(2) {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          animation: closeBarBackwardTwo 1s ease-in-out;
        }

        &:last-of-type {
          animation: closeBarBackwardThree 1s ease-in-out;
        }
      }
    }
    &.plus {
      @extend %container-24x24;
      div {
        &:nth-child(2) {
          animation: plusBarBackwardTwo 1s ease;
        }
        &:first-of-type,
        &:last-of-type {
          width: 30%;
        }
        &:first-of-type {
          animation: plusBarBackwardOne 0.9s ease-in-out;
        }
        &:last-of-type {
          animation: plusBarBackwardThree 0.9s ease-in-out;
        }
      }
    }
  }
}

/* Effects */
.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes closeBarBackwardOne {
  0% {
    width: 100%;
    transform: rotate(0deg) translate(0, 0);
  }
  30% {
    width: 30%;
    transform: rotate(0deg) translate(0, 0);
  }
  100% {
    width: 30%;
    transform: rotate(225deg) translate(-240%, 100%);
  }
}
@keyframes closeBarForwardTwo {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(315deg);
  }
}
@keyframes closeBarForwardThree {
  0% {
    width: 100%;
    transform: rotate(0deg) translate(0, 0);
  }
  30% {
    width: 30%;
    transform: rotate(0deg) translate(0, 0);
  }
  100% {
    width: 30%;
    transform: rotate(45deg) translate(-65%, -570%);
  }
}
// Close Backward Animation
@keyframes closeBarBackwardOne {
  0% {
    width: 30%;
    transform: rotate(225deg) translate(-240%, 100%);
  }
  30% {
    width: 30%;
    transform: rotate(0deg) translate(0, 0);
  }
  100% {
    width: 100%;
    transform: rotate(0deg) translate(0, 0);
  }
}
@keyframes closeBarBackwardTwo {
  0% {
    margin: -2% 0 0 -50%;
    transform: rotate(315deg);
  }
  100% {
    margin: -3% 0 0 -50%;
    transform: rotate(0deg);
  }
}
@keyframes closeBarBackwardThree {
  0% {
    width: 30%;
    transform: rotate(45deg) translate(-65%, -570%);
  }
  30% {
    width: 30%;
    transform: rotate(0deg) translate(0, 0);
  }
  100% {
    width: 100%;
    transform: rotate(0deg) translate(0, 0);
  }
}
// Plus Fordward Animation
@keyframes plusBarForwardOne {
  0% { transform: rotate(0deg) translate(0, 0);}
  50% { transform: rotate(150deg) translate(-300%, 400%); }
  100% { transform: rotate(225deg) translate(-200%, 250%); }
}
@keyframes plusBarForwardTwo {
  0% { transform: rotate(90deg); }
  100% { transform: rotate(-45deg); }
}
@keyframes plusBarForwardThree {
  0% { transform: rotate(0deg) translate(240%, 0); }
  100% { transform: rotate(225deg) translate(30%, 250%); }
}
// Plus Backward Animation
@keyframes plusBarBackwardOne {
  0% { transform: rotate(225deg) translate(-200%, 250%); }
  50% { transform: rotate(150deg) translate(-300%, 400%); }
  100% { transform: rotate(0deg) translate(0, 0); }
}
@keyframes plusBarBackwardTwo {
  0% { transform: rotate(-45deg); }
  100% { transform: rotate(90deg); }
}
@keyframes plusBarBackwardThree {
  0% { transform: rotate(225deg) translate(30%, 250%); }
  100% { transform: rotate(0deg) translate(240%, 0); }
}
// Bounces
@keyframes bounce {
	0%, 20%, 50%, 80%, 100% {
    transform:translateY(0);
  }
	40% {
    transform:translateY(-30px);
  }
	60% {
    transform:translateY(-15px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  60% {
    transform: translateX(-15px);
  }
}
@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 15px);
  }

  100% {
    transform: translate(0, -0px);
  }
}
@keyframes pulse {
  0% {
    transform: scale(.8);
    opacity: .8;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(.8);
    opacity: .8;
  }
}