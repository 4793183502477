@import '../../stylesheets/variables';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.modal {
  
  .get-in-touch {
    text-align: center;
    h3 {
      font-size: 1;
      @include media-breakpoint-up(lg) {
        font-size: 1.125rem;
      }
    }
    p {
      font-size: 1rem;
    }
  }
}