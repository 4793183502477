@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  &__menu {
    position: absolute;
    display: inline-block;
    bottom: 1rem;
    left: 1rem;
    font-size: 1.25rem;
    border: 0;
    padding: .25rem .75rem;
    color: rgba(255, 255, 255, .5);
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.2);
      color: rgba(255, 255, 255, 1);
    }
  }
  &__nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__nav nav {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;

    &.active {
      display: block;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
      }
    }

    ul {
      position: relative;
      display: flex;
      justify-content: center;
      top: 50%;
      left: 0;
      width: 80%;
      transform: translateY(-50%);
      margin: 0 auto;
      z-index: 1;
      div:first-of-type {
        margin-right: 3rem;
      }
      li {
        color: var(--bs-white);
        white-space: nowrap;
        line-height: 2.5rem;
        cursor: pointer;
        &::before {
          display: none;
        }
      }
    }
  }
}