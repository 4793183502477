@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  .scene-3 {
    &__retail-space-bg {
      top: 0;
      left: 0;
      width: 100%;
    }
    &__device-1 {
      // QPP-450 Pink
      width: 12%;
      left: 24%;
      bottom: 32%;
    }
    &__device-2 {
      // QPP-450
      width: 35%;
      left: 33%;
      bottom: 10%;
    }
    &__device-3 {
      // QPPRO+
      width: 19%;
      left: 65%;
      bottom: 9%;
    }
    &__device-4 {
      // Google Tablet with Flexcase
      width: 45%;
      left: 4%;
      bottom: 30%;
    }
    &__creditcard {
      // Credit card
      width: 21%;
      left: 56.5%;
      bottom: 70%;
    }
    &__connection {
      position: absolute;
      width: 8%;
      top: 28%;
      left: 49%;
      transform: translate(-50%, -50%);
      @include media-breakpoint-up(lg) {
        top: 23%;
      }
    }
    &__payments-text {
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2rem;
      white-space: nowrap;
      margin-bottom: .5rem;
      color: var(--bs-white);
      font-weight: 700;
      text-align: center;
      @include media-breakpoint-up(lg) {
        top: 50%;
        font-size: 3rem;
      }
    }
  }
}