@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  .scene-6 {
    &__rfid-event-admission-wristbands {
      position: absolute;
      top: 50%;
      left: 25%;
      width: 15%;
      transform: translate(-50%, -50%);
      img {
        position: absolute;
        transform: translate(0, -50%);
      }
    }
    &__rfid-wristband--payment {
      top: 45%;
      left: 70%;
      width: 25%;
      transform: translate(-50%, -50%);
    }
    &__google-pay, &__apple-pay {
      position: absolute;
      width: 20%;
      left: 40%;
    }
    &__google-pay {
      top: 20%;
    }
    &__apple-pay {
      top: 70%;
    }
    &__lpr-ticket-admission,
    &__lpr-accept-mobile-payments {
      width: 20%;
      bottom: 10%;
      transform: translateY(120%);
    }
    &__lpr-ticket-admission {
      left: 3%;
    }
    &__lpr-accept-mobile-payments {
      right: 3%;
    }
  }
}