@import '../../../../stylesheets/variables';
@import '../../../../stylesheets/animations';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.we-are-animation {
  // .scenes__scene1 {}
  .scene-1 {
    &__intro-text {
      text-align: center;
      color: var(--bs-white);
    }

    &__collage {
      --collageImageHeight: calc(80vw * .5625);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      height: var(--collageImageHeight);
      gap: 4vw;
      margin: auto;

      &>div {
        display: flex;
        flex-direction: column;
        height: var(--collageImageHeight);
        gap: 3vh;
      }

      img {
        --collageImageHeight: 32vh;
        width: calc(var(--collageImageHeight) / 0.5625);
        max-width: auto;
        height: var(--collageImageHeight);

        @include media-breakpoint-up(lg) {
          --collageImageHeight: 22vh;
        }
      }
    }

    &__experiences-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      div {
        text-align: center;
        font-weight: 700;
        white-space: nowrap;
        letter-spacing: 1.5px;
        line-height: 1.3;

        &:first-of-type {
          font-size: 6.75vw;
        }

        &:nth-child(2) {
          font-size: 4.25vw;
        }

        &:last-of-type {
          font-size: 3.75vw;
        }
      }
    }
  }
}