@import '../../stylesheets/variables';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import '../../stylesheets/vendor/instant-search';


.layout.devices {
  #main {
    padding-top: 50px;
    .section {
      border-bottom: 2px solid #f6f6f6;
      padding-bottom: 15px;
      margin-bottom: 70px;
      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
  #footer-wrap {
    margin-top: 70px;
  }
}