@import '../../stylesheets/variables';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/color-mode";

$color-mode-type: data;

.cp_billboard-homepage-2024 {
    @include media-breakpoint-up(lg) {
        overflow: hidden;
    }
    .carousel {
        &-inner {
            height: 800px;

            @include media-breakpoint-up(lg) {
                height: 600px;
            }

            @include media-breakpoint-up(xl) {
                height: 700px;
            }

            @include media-breakpoint-up(xxl) {
                height: 800px;
            }
        }

        &-item {

            &,
            .container,
            .row,
            .col {
                height: 100%;
            }

            .col {
                @include media-breakpoint-up(lg) {
                    display: flex;
                }
            }

            h2 {
                font-size: 2rem;
                color: white;
                font-weight: 700;

                @include media-breakpoint-up(lg) {
                    font-size: 2.5rem;
                    margin-top: 3rem;
                    margin-bottom: 3.5rem;
                }

                @include media-breakpoint-up(xl) {
                    font-size: 3.25rem;
                    margin-top: 3rem;
                    margin-bottom: 5rem;
                }
            }

            p {
                font-size: 1.65rem;
                @include media-breakpoint-up(lg) {
                    font-size: 1.5rem;
                }

                @include media-breakpoint-up(xl) {
                    font-size: 2rem;
                }
            }

            .cta-links {
                @include media-breakpoint-down(lg) {
                    position: absolute;
                    bottom: 4rem;
                    width: 100%;
                    display: inline-flex;
                    justify-content: space-evenly;
                }
            }

            .btn-link {
                text-decoration: none;
            }
        }

        &-indicators {
            margin-bottom: .5rem;

            @include media-breakpoint-up(lg) {
                margin-bottom: 2rem;
            }

            &__container {
                padding: 0 .5rem;
                border-radius: 1rem;

                @include media-breakpoint-up(lg) {
                    padding: 0 .75rem;
                }
            }

            button {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                margin-left: .25rem;
                margin-right: .25rem;

                @include media-breakpoint-up(lg) {
                    width: 10px;
                    height: 10px;
                }
            }
        }

        &-control {

            &-prev,
            &-next {
                width: 5%;
            }
        }
    }

    .item__content {
        margin-top: 4rem;
        z-index: 1;

        @include media-breakpoint-up (lg) {
            margin-top: auto;
            margin-bottom: auto;
        }

        @include media-breakpoint-between (xl, xxl) {
            margin-left: 5rem;
        }
    }

    .swoosh {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(90deg, #357AFE 0.22%, #544BFD 36%, #6F24FC 100%);
        z-index: -1;

        @include media-breakpoint-up(lg) {
            height: 45%;
        }

        &::after {
            position: absolute;
            content: "";
            bottom: 0;
            background-image: url("https://res.cloudinary.com/ipcmobile/image/upload/v1706645616/home/v2/blue-purple-gradient.svg");
            background-repeat: no-repeat;
            width: 100vw;
            height: 100vw;
            transform: translateY(99%);

        }
    }

    .nrf-2025 {
        .container-fluid {
            @include media-breakpoint-up(md) {
                width: 85%;
            }
        }

        .ipc-logo {
            width: 80%;
            margin-bottom: 2rem;
            @include media-breakpoint-up(md) {
                width: 40%;
                margin-bottom: 0;
            }
        }

        .plus-sign {
            margin-bottom: 2rem;
        }

        .gpfb {
            // width: 80%;
            @include media-breakpoint-up(md) {
                width: 40%;

            }
        }

        .btn {
            font-size: 1rem;
        }
    }

    .lpg {
        img.linea-pro-google-pixel-battery-header {
            position: absolute;
            width: 75%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -35%);

            @include media-breakpoint-up(sm) {
                width: 80%;
            }

            @include media-breakpoint-up(md) {
                width: 40%;
                margin-top: -2rem;
            }
        }

        video.linea-pro-google-pixel-battery-header {
            position: absolute;
            height: 100%;
            top: 0;
            right: -25%;
        }
    }

    .lpr {
        img.linea-pro-for-iphone-13-14 {
            position: absolute;
            width: 75%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -35%);

            @include media-breakpoint-up(sm) {
                width: 80%;
            }

            @include media-breakpoint-up(md) {
                width: 40%;
                margin-top: -2rem;
            }
        }

        video.linea-pro-for-iphone-13-14 {
            position: absolute;
            height: 100%;
            top: 0;
            right: -25%;
        }
    }

    .nexusconnect-uhf {
        img.nexusconnect-uhf-multi-device {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -40%);

            @include media-breakpoint-up(sm) {
                width: 80%;
            }

            @include media-breakpoint-up(md) {
                width: 60%;
                margin-top: -2rem;
            }
        }

        video.nexusconnect-uhf-multi-device {
            position: absolute;
            height: 90%;
            top: 10%;
            right: -20%;
        }

        .os-logos {
            position: absolute;
            display: none;
            top: 2rem;
            right: 15%;
            z-index: 1;

            @include media-breakpoint-up(lg) {
                display: flex;
                gap: 1rem;
            }

            .android-logo {
                width: 200px;
            }

            .apple-logo {
                width: 100px;
            }
        }
    }

    .haloring {
        img.halring-colored-bumpers {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -40%);

            @include media-breakpoint-up(sm) {
                width: 80%;
            }

            @include media-breakpoint-up(md) {
                width: 60%;
                margin-top: -2rem;
            }
        }

        video.halring-colored-bumpers {
            position: absolute;
            height: 80%;
            top: 15%;
            right: -25%;
        }
    }

}

[data-bs-theme="light"] {
    .cp_billboard-homepage-2024 {
        .carousel {
            &-indicators {
                button {
                    background-color: rgba($color: #000000, $alpha: .25);
                }

                &__container {
                    background-color: rgba($color: #000000, $alpha: .05);
                }
            }

            &-item {
                p {
                    @include media-breakpoint-up(lg) {
                        color: var(--bs-body-color);
                    }
                }

                .btn-link {
                    --bs-btn-color: var(--bs-dark);
                    --bs-btn-hover-color: var(--bs-dark);
                    --bs-btn-active-color: var(--bs-dark);

                    img {
                        filter: invert(1);
                    }
                }
            }

            &-control {

                &-prev-icon,
                &-next-icon {
                    filter: invert(0.15);
                }
            }
        }
    }
}

[data-bs-theme="dark"] {
    .cp_billboard-homepage-2024 {
        .carousel {
            &-item {
                .btn-link {
                    --bs-btn-color: var(--bs-white);
                    --bs-btn-hover-color: var(--bs-white);
                    --bs-btn-active-color: var(--bs-white);
                }
            }

            &-indicators {
                button {
                    background-color: rgba($color: #ffffff, $alpha: .5);
                }

                &__container {
                    background-color: rgba($color: #000000, $alpha: .25);
                }
            }

            &-control {

                &-prev-icon,
                &-next-icon {
                    filter: invert(.75);
                }
            }
        }
    }
}