// Colors

// custon variables
$black: #000000;
$midnight: #242424;
$off-white: #f2f2f2;
$light-gray: #f6f6f6;
$gray: #ececec;
$dark-gray: #b5b5b5;
$ipc-green: #65D007;
$light-blue: #3479FD;

$red: #e92629;
$orange: #ff5900;
$blue: #4091fe;
$yellow: #ffcc00;

//industries
$healthcare: #03d5ff;
$hospitality: #e3d800;
$retail: #eb2300;
$supplychain: #ff5900;
$travel: #8347fc;

//industries
$custom-colors: (
  "healthcare": #03d5ff,
  "hospitality": #e3d800,
  "retail": #eb2300,
  "supplychain": #ff5900,
  "travel": #8347fc
);

//Bootstrap Color Overwrites
$primary: $ipc-green;
$secondary: $midnight;
$tertiary: $light-blue;
$success: $primary;
$danger: $retail;
$warning: $hospitality;
$info: $healthcare;
$light: $off-white;
$dark: $midnight;
$white: #ffffff;
$transparent: transparent;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  3xl: 1600px //Add custom breakpoint for 3xl
);



// Font-family variables
%default_font-family {
  font-family: HelveticaNeue, aktiv-grotesk, sans-serif;
}

//Prefixes
$prefix4H: "https://res.cloudinary.com/ipcmobile/image/upload/c_scale,q_auto:good,f_auto,w_400/";
$prefix8H: "https://res.cloudinary.com/ipcmobile/image/upload/c_scale,q_auto:good,f_auto,w_800/";
$prefix1K: "https://res.cloudinary.com/ipcmobile/image/upload/c_scale,q_auto:good,f_auto,w_1000/";
$prefix1K5H: "https://res.cloudinary.com/ipcmobile/image/upload/c_scale,q_auto:good,f_auto,w_1500/";
$prefix2K: "https://res.cloudinary.com/ipcmobile/image/upload/c_scale,q_auto:good,f_auto,w_2000/";
$prefix3K: "https://res.cloudinary.com/ipcmobile/image/upload/c_scale,q_auto:good,f_auto,w_3000/";
$prefix4K: "https://res.cloudinary.com/ipcmobile/image/upload/c_scale,q_auto:good,f_auto,w_4000/";
$prefixIcon: "https://res.cloudinary.com/ipcmobile/image/upload/";

// Page Specific

$healthcareHero: "v1548695611/healthcare/healthcare-infinite-peripherals-heallthcare-solutions-healthcare-technology-health-technology.webp";
$healthcareVideo: "v1545069447/healthcare/healthcare-infinite-peripherals-mobile-device-helathcare-it-linea-pro.webp";
$hospitalityHero: "v1544822946/hospitality/hospitality-infinite-peripherals-hospitality-solutions-mobile-solutions-guest-services.webp";
$hospitalityVideo: "v1544465992/hospitality/hospitality-infinite-peripherals-pos-mobile-payments-guest-services.webp";
$retailHero: "v1544741632/retail/retail-infinite-peripherals-retail-solutions-retail-experience-omnichannel-clienteling-retail-management.webp";
$supplychainHero: "v1544806500/supplychain/supply-chain-infinite-peripherals-warehouse-management-supply-chain-management-logistics.webp";
$supplychainVideo: "v1544466009/supplychain/supply-chain-fleet-safety-fleet-management-mobile-solutions.webp";
$travelHero: "v1544741086/travel/travel-infinite-peripherals-travel-solutions-mobile-solutions-terminal-solutions.webp";
$travelVideo: "v1544466022/travel/travel-infinite-peripherals-omni-air-omni-air-international.webp";

$paymentHero: "v1545338518/payment/payment-infinite-peripherals-pos-mobile-payments-mobile-pay-point-of-sale.webp";
$paymentCTAhero: "v1544810885/payment/payment-your-way.webp";
$scanningHero: "v1544467626/scanning/scanning-infinite-peripherals-barcode-scanner-barcode-scanner-app-qr-code-reader.webp";
$scanningCTAhero: "v1607116789/scanning/scanning-your-way.webp";
$printingHero: "v1544467658/printing/printing-infinite-peripherals-mobile-printer-mobile-printing-solutions-print-solutions.webp";
$printingCTAhero: "v1544811230/printing/printing-your-way.webp";

$infineaRetailHero: "v1544468067/software/infinea-retail.webp";
$ezmetrHero: "v1545417121/software/digital-platform-taxi-meter-cab-service.webp";
$infineaIQHero: "v1555365265/software/infineaiq-thumb.webp";
$connectionServicesHero: "v1556639234/connection-services/connection-services-hero.webp";